.block-list {
  @include block-grid(1);
  margin-bottom: vr(1.5) !important;

    margin-left: -$column-gutter/2 !important;
    margin-right: -$column-gutter/2 !important;


  @media only screen and (min-width: 30em) {
    @include block-grid(2);
  }
  @media #{$medium-up} {
    @include block-grid(3);
  }
  @media #{$large-up} {
    @include block-grid(4);
  }
  li {
    margin-bottom: 0;
    padding-bottom: 0;
    &:before {
      display: none;
    }
  }
  &--centered {
    font-size: 0;
    text-align: center;
    div {
      text-align: left;
    }
    >li {
      @include typi(s0);
      display: inline-block;
      float: none;
      vertical-align: middle;
    }
  }

  &--data {
    @include block-grid(2);
    margin-bottom: 0 !important;
    @media #{$medium-up} {
      @include block-grid(5);
      margin-bottom: 0 !important;
    }
    // @media #{$large-up} {
    //   @include block-grid(5);
    // }
    >li {
      padding-bottom: 0 !important;
    }
    .data-wrapper {
      padding: vr(.75) 0 vr(1);
      position: relative;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 4em;
        height: 1px;
        background-color: #fff;
        left: 50%;
        margin-left: -2em;
        @media #{$large-up} {
          width: 9em;
          margin-left: -4.5em;
        }
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
      @media #{$medium-up} {
        padding: vr(1) 0 vr(1.5);
      }
      @media #{$large-up} {
        padding: vr(1) 0;
      }
    }
    @media #{$small-only} {
      li:first-child,
      li:nth-child(2),
      li:nth-child(3) {
        .data-wrapper {
            &:after {
              display: none;
            }
        }
      }
    }
    // @media #{$medium-only} {
    //   li:first-child {
    //     .data-wrapper {
    //         &:after {
    //           display: none;
    //         }
    //     }
    //   }
    // }
    .num {
      @include typi('data');
      @include black;
      display: block;
      letter-spacing: 1.2px;
      text-align: center;
      margin-bottom: vr(.25);
    }
    .name {
      @include typi('data-name');
      @include semibold;
      text-transform: uppercase;
      display: block;
      text-align: center;
      @media #{$medium-up} {
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  &--icons {
    margin-top: vr(1);
    margin-bottom: 0 !important;
    @include block-grid(2);
    @media #{$medium-up} {
      @include block-grid(4);
    }
    li {
      padding-top: vr(3);
      @media #{$medium-up} {
        padding-top: vr(3.75);
        position: relative;
        padding-bottom: 0 !important;
      }
      @media #{$large-up} {
        padding-top: vr(5);
      }
      // &:before {
      //   content: '';
      //   display: block;
      //   background-color: transparent;
      //   position: static;
      //   border-radius: 0;
      //   margin-bottom: vr(.25);
      //   font-size: .675em;
      //   @media #{$medium-up} {
      //     top: 0;
      //     left: 50%;
      //     transform: translateX(-50%);
      //     position: absolute;
      //   }
      //   @media #{$large-up} {
      //     font-size: 1em;
      //   }
      // }
      // &.empleo:before {
      //   @include sprite('ico-empleo');
      // }
      // &.formacion:before {
      //   @include sprite('ico-formacion');
      // }
      // &.convocatorias:before {
      //   @include sprite('ico-convocatorias');
      // }
      // &.colabora:before {
      //   @include sprite('ico-colabora');
      // }
    }
    .btn {
      display: block;
      width: 10em;
      margin: 0 auto vr(.52);
      padding-left: vr(.5);
      padding-right: vr(.5);
      @media #{$medium-up} {
        margin: 0 auto vr(2);
        width: 11em;
        width: 100%;
        margin-bottom: 0;
      }
      @media #{$large-up} {
        width: 90%;
      }
    }
    img {
      top: 7%;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 3rem;
      @media #{$medium-up} {
        top: 0;
        width: 5rem;
      }
      @media #{$large-up} {
        width: auto;
      }
    }
  }
  &--box {
    margin-top: 20px;
    li {
      color: #fff;
      a {
       display: block;
       background-color: $primary-color;
       color: #fff;
       border-radius: 8px; 
       font-size: 19px;
       @include semibold;
       padding: 20px;
       min-height: 130px;
       border-bottom: none;
      }
    }
  }
  &--news {
    //margin-top: vr(2) !important;
    margin-bottom: 0 !important;
    @media #{$large-up} {
      @include block-grid(3);
    }
    li {
      margin-bottom: vr(1.5);
      @media #{$large-up} {
        margin-bottom: 0;
      }
    }
    .wrapper {
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: vr(1);
    }
    a {
      border: 0;
      color: $body-font-color;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }

  &--events {
    margin-top: vr(2) !important;
    margin-bottom: 0 !important;
    @media #{$large-up} {
      @include block-grid(3);
    }
    // li {
    //   margin-bottom: vr(1.5);
    //   @media #{$large-up} {
    //     margin-bottom: 0;
    //   }
    // }
    // a {
    //   border: 0;
    //   color: $body-font-color;
    //   &:hover {
    //     border-bottom: 1px solid;
    //   }
    // }
  }

  &--boxes {
    margin-top: vr(-5);
    @media #{$medium-up} {
      margin-top: vr(-10);
    }
    @media #{$large-up} {
      @include block-grid(3);
      margin-top: vr(-10);
    }
    li {
      @media #{$large-up} {
        padding-bottom: 0;
      }
      
      &.other {
        .block-list__box {
            background-color: #fff;
            border: 1px solid $primary-color;
            a {
                color: $primary-color;
            }
        }
      }
    }
  }

  &__box {
    background-color: $primary-color;
    color: #fff;
    padding: vr(1);
    margin-bottom: vr(1);
    >*:last-child {
      margin-bottom: 0;
    }
    h2 {
      @include typi('big');
      @include bold;
      border-bottom: 1px solid #fff;
      border-bottom-color: rgba(#fff,.4);
      padding-bottom: vr(.5);
      margin-bottom: vr(-.5);
    }
    ul {
      @include typi('s0');
      a {
        display: block;
        padding: vr(.5) 0;
      }
    }
    li {
      list-style: none;
      border-bottom: 1px solid #fff;
      border-bottom-color: rgba(#fff,.4);
      margin: 0;
      &:last-child {
        border-bottom: none;
      }
    }
    a {
      color: #fff;
      border: 0;
      &:hover {
        border: 0;
        text-decoration: underline;
      }
    }
  }
}

