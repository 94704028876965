.basic {
  // margin-bottom: vr(1);
  fieldset {
    border: 0;
    padding: 0 0 vr(1);
    margin: 0 0 vr(1.5);
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: vr(8);
      margin: 0 auto;
      background-color: #fff;
      position: relative;
      bottom: vr(-1);
    }
    &:last-child {
      padding-bottom: 0;
      &:after {
        display: none;
      }
    }
  }
  legend {
    @include typi('t2');
    @include regular;
    text-align: center;
    padding-bottom: vr(.5);
    width: 100%;
    .colabora & {
      @include typi('s0');
      @include light;
    }
  }
  label {
    display: block;
    text-align: left;
    margin-bottom: vr(.25);
  }
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="number"],
  input[type="url"],
  select,
  textarea {
    // @include typi(s1);
    width: 100%;
    padding: rem-calc(9) vr(.5);
    color: #4c4c4c;
    background-color: #f8f8f8;
    // border: 1px solid #cdcdcd;
    margin-bottom: vr(1);
    border-radius: 4px;
    &:focus {
      background-color: darken(#f8f8f8,5%);
    }
    &.error {
     border: 1px solid red;
     .section--6 &,
     .section--4 & {
       margin-bottom: 0;
       border: 1px solid red;
       border-bottom: 0;
       border-bottom-left-radius: 0;
       border-bottom-right-radius: 0;
     }
   }
  }
  

  // textarea {
  //   height: rem-calc(245);
  // }
  input[type="search"] {
    box-sizing: border-box;
  }
  // button {
  //   width: 100%;
  // }
}
// .radios {
//   text-align: center;
//   p {
//     margin-bottom: vr(1);
//     @media only screen and (min-width: 30em) {
//       display: inline-block;
//       margin-right: vr(2);
//     }
//   }
//   label {
//     margin-bottom: vr(1);
//     margin-right: vr(1);
//   }
// }

// validació
label.error {
  @include typi(s1);
  margin-top: vr(-.75);
  color: red;
  display: block;

  .section--6 &,
  .section--4 & {
   padding: vr(.25);
   background-color: red;
   color: #fff;
   margin-top: 0;
   margin-bottom: vr(.5);
   padding: vr(.25) vr(.5);
   display: block;
   border-radius: 0 0 4px 4px;
  }
}
.checkboxes label.error{
  display: inline-block;
}
button {
  margin-top: 20px;
}
// newsletter
.nl-form {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  @media #{$medium-up} {
    width: 45%;
  }
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
  label:not(.error) {
    @include visually-hidden;
  }
  label.error {
    text-align: left;
    padding: vr(.25);
    background-color: red;
    color: #fff;
    margin-top: 0;
    margin-bottom: vr(.5);
    padding: vr(.25) vr(.5);
    display: block;
    border-radius: 0 0 4px 4px;
  }
  input {
    width: 100%;
    margin-bottom: vr(1);
    border-radius: 4px;
    background-image: linear-gradient(to bottom, #d5d5d5, #ffffff);
    padding: vr(.25) vr(.5);
    color: $body-font-color;
    &.error {
      margin-bottom: 0;
      border: 1px solid red;
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  button {
    margin-bottom: 0;
  }
}
.row.checkboxes {
  margin-top: vr(.5);
  margin-bottom: vr(1);
}

// estils per input[type="file"]
.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  display: block;
  cursor: pointer;
  padding: rem-calc(9) vr(.5);
  color: #4c4c4c;
  background-color: #f8f8f8;
  margin-bottom: vr(1);
  border-radius: 4px;
}
.upload-icon {
  fill: $primary-color;
}
.no-js .inputfile + label {
    display: none;
}
.inputfile:focus + label,
.inputfile + label:hover {
  background-color: darken(#f8f8f8,5%);
}
.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}
.inputfile + label * {
  pointer-events: none;
}

label + label.error {
	margin-top: -1.75rem !important;
}

// select
label.inline,
.select.inline {
  display: inline-block;
}


// form catàleg oferta tencològica
.ctlg {
  margin-bottom: 0;
  legend {
    @include visually-hidden;
  }
  fieldset {
    margin-bottom: 0;
  }
  .btn {
    margin-bottom: 0;
  }
}

// form donacions
.radios {
  label {
    display: inline-block;
    border: 1px solid;
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(8);
    padding-right: vr(.5);
    padding-left: 1.75rem;
    border-radius: 4px;
    background-color: rgba(255,255,255,.2);
      
      // per si els labels han de ser inline, descomentar:
      // -------------------------------------------------
      // display: inline;
      // margin-left: .5rem;
      // margin-right: .5rem;
      // line-height: 4;
  }
  .control-indicator {
    top: .75rem;
    left: 7px;
  }
  input.number {
    position: static;
    opacity: 1;
    z-index: 0;
    margin: 0 5px;
    width: 4rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}
form .dninifnie .select {
    margin-right: $column-gutter;
}

  .select.error:after {
  		top: 33px;
  }
