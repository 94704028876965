.status {
	text-transform: uppercase;
	display: block;
	@include typi('small');
	@include bold;
	margin-bottom: vr(1);
	text-align: center;
	&:before {
		content: '';
		width: vr(2);
		height: vr(2);
		display: block;
		border-radius: 100%;
		margin: 0 auto vr(.25);
	}
    @media #{$medium-up} {
        position: absolute;
        top: vr(1.5);
        right: 0;
        .empleo-item & {
            width: 7rem;
        }
        .panel--conv & {
            right: vr(2);
        }
        .empleo.view &,
        .convocatoria.view &,
        .licitacion.view & {
            position: static;
			text-align: left;
            &:before {
				margin-left: 0;
				margin-bottom: 0.375rem;
			}
        }
    }
    @media #{$large-up} {
        .empleo-item & {
            width: 8rem;
        }
    }
	&--open {
		&:before {
			background-color: #91ecaa;
		}
	}
	&--deserted {
		&:before {
			background-color: #fff086;
		}
	}
	&--resolved {
		&:before {
			background-color: #afacac;
		}
	}
	&--closed {
		&:before {
			background-color: #ff7182;
		}
	}
}