.breadcrumb {
  @include typi('big');
  @include light;
  margin-bottom: 0;
    margin-bottom: vr(1);
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: vr(5);
    background-color: #000;
    margin: vr(1) auto 0;
  }
  a {
    border: 0;
    color: $body-font-color;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}

.sub__section {
	text-transform: uppercase;
	padding: 2rem 0;
	margin-top: -1.5rem;
	margin-bottom: 2rem;
	text-align: left;
	
	@media #{$medium-up} {
        margin-top: -2.25rem;
    }
	@media #{$large-up} {
        margin-top: -3rem;
/*         padding: 3.75rem 0 2.25rem;; */
    }
    
	a {
	   color: #fff;
	   text-decoration: none;
	   border: none;
	}
	&--parent {
	   font-family: TitilliumWeb-ExtraLight,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
       font-size: rem-calc(20);
       color: #fff;
       margin-bottom: 0;
       line-height: 1.35;
       font-weight: 600;
	}
	
	&--title {
	   font-family: TitilliumWeb-SemiBold,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
       font-size: rem-calc(32);
       color: #fff;
       margin-bottom: 0;
       line-height: 1.35;
       font-weight: 600;
	}
}