.share {
  margin-top: vr(2);
   margin-bottom: vr(1);
  .panel--conv & {
    border-top: 1px solid #979797;
    padding-top: vr(1);
    margin-top: vr(1);
  }
  .empleo & {
    border-bottom: 1px solid #979797;
    padding-bottom: vr(1);
    margin-bottom: vr(1.5) !important;
    margin-top: 0;
  }
  p {
    // @include typi('big');
    @include semibold;
    text-transform: uppercase;
    margin-bottom: vr(.5);
  }
  li {
    margin-top: 0;
  }
  .share-desktop{
    display: none;
    @media #{$medium-up} {
      display: inline;
    }
  }
  @media #{$medium-up} {
    .share-mobile{
      display: none;
    }
  }
}

