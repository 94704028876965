.main-footer {
  text-align: center;
  //margin-top: 15px;
  &--1 {
    background: #333 url('img/imatge-peu-mobil.jpg') 50% 100% no-repeat;
    background-size: cover;
    padding: vr(2) 0;
    @media #{$medium-up} {
      background-image: url('img/imatge-peu-tablet.jpg')
    }
    @media #{$large-up} {
      background-image: url('img/imatge-peu.jpg')
    }
    p {
      color: #fff;
      &:first-of-type {
        margin-bottom: 0;
      }
    }
    a {
      color: #fff;
    }
  }
  &--2 {
    padding: vr(2) 0;
    ul {
      margin-bottom: 0;
    }
    li {
      margin-left: 0;
      margin-right: 0;
      &:after {
        content: '|';
        color: #55514e;
        display: inline-block;
        vertical-align: baseline;
        margin: 0 vr(.25);
      }
      &:last-child:after {
        display: none;
      }
    }
    a {
      color: #55514e;
    }
  }
  a {
    text-decoration: none;
    border: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
  p,li {
    @include regular;
    @include typi('s1');
  }
  .adr {
    a {
      color: #78e0f8;
    }
  }
  .dot {
    display: none;
    @media #{$medium-up} {
      display: inline;
      margin: 0 .5em;
    }
  }
  .br-1 {
    display: block;
    @media #{$medium-up} {
      display: inline;
    }
  }
  .br-2 {
    @media #{$medium-up} {
      display: block;
    }
  }
}
.social {
  li {
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
  @media #{$medium-up} {
    display: block;
    margin-bottom: vr(1);
    border-bottom: 1px solid #fff;
    border-bottom-color: rgba(#fff,.5);
    padding-bottom: vr(1);
    li {
      margin-left: vr(1.75);
      margin-right: vr(1.75);
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  @media #{$large-up} {
    border: 0;
    padding-bottom: 0;
    margin-top: vr(1);
    margin-bottom: vr(1.5);
  }
}
.site-nav {
  display: none;
  @media #{$large-up} {
    @include bold;
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 1px solid #fff;
    border-bottom-color: rgba(#fff,.5);
    padding-bottom: vr(1);
  }
}
.links {
  display: none;
  @media #{$medium-up} {
    display: block;
    margin-bottom: 0;
  }
}
