@include typi-init;
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
}
h1,
.t1 {
  @include typi('t1');
  @include light;
  //text-align: center;
  margin-bottom: vr(1.5);
  .grupo &,
  .plataforma & {
    @include typi('t2');
    @include bold;
    text-transform: uppercase;
    @media #{$large-up} {
      margin-bottom: vr(2);
    }
  }
  .view & {
    @include bold;
  }
  .colabora.view & {
    @include light;
  }
  .investigacion.grupo &,
  .servicios.plataforma & {
    margin-bottom: vr(1);
  }
  .servicios.subhome &,
  .empleo & {
    margin-bottom: 0;
  }
  .empleo.view & {
    margin-bottom: vr(1.5);
  }
}
h2,
.t2 {
  @include typi('t2');
  @include light;
  margin-bottom: vr(1);
  &:not(:first-child) {
    // padding-top: vr(1.5);
  }
  .section--vmv & {
    margin-bottom: vr(.5);
    @include semibold;
    @include typi('bigger');
  }
  .servicios &,
  .oferta.view & {
    @include typi('s0');
    @include bold;
    text-transform: uppercase;
  }
  .equip-wrapper & {
    text-transform: none;
    position: relative;
    padding-left: vr(.5);
    @include semibold;
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      display: block;
      left: 0;
      top: .6em;
      position: absolute;
      background-color: $link-color;
    }
  }
}
h3,
.t3 {
  @include typi('t3');
  @include semibold;
  margin-bottom: vr(.5);
  &:not(:first-child) {
    // padding-top: vr(1.5);
  }
  &.small {
    margin-bottom: 0;
  }
  .panel & {
    padding-top: 0;
  }
  .colab-block & {
    @include typi('t4');
    text-transform: uppercase;
    margin-bottom: vr(1);
  }
}
h4,
.t4 {
  @include typi('t4');
  @include regular;
  margin-bottom: vr(.5);
  .column-block & {
    // white-space: pre-wrap;
    word-wrap: break-word;
  }
}
.s0 {
  @include typi('s0');
}

.boxed {
  @include typi('big');
  @include bold;
  text-transform: uppercase;
  background-color: $primary-color;
  // background-image: linear-gradient(to right, #0056a4 40%, #fff);
  color: #fff;
  padding: vr(1) 2em vr(1) vr(1) !important;
}
.brd {
  border-bottom: 1px solid #979797;
  padding-bottom: vr(1);
  margin-bottom: vr(1);
  .section--2 & {
    border-bottom-color: #fff;
  }
}
.brd-2 {
  border: 1px solid #979797;
  border-width: 1px 0;
  padding: vr(.75) 0 !important;
  margin-bottom: vr(1);
  .section--2 & {
    border-color: #fff;
  }
}
.donar {
  text-transform: uppercase;
  @include bold;
  margin-bottom: vr(2);
  &:before {
    content: '';
    display: block;
    margin: 0 auto vr(1);
    @include sprite('ico-donacion-boton-negatiu');
    font-size: 1rem;
  }
}
p, ol, ul, dl, figure {
  margin: 0 0 vr(1);
}
.small {
  @include typi('small');
}
.big {
  @include typi('big');
}
.bigger {
  @include typi('bigger');
}

.dl-inline {
  width: 100%;
  overflow: hidden;
  dt {
    @include semibold;
    float: left;
    clear: left;
    margin-right: vr(.25);
  }
  dd {
    @include regular;
    margin: 0;
  }
}

.dl-stacked {
  dt {
    @include semibold;
    margin-top: vr(.5);
    &:first-child,
    .responsable & {
      margin-top: 0;
    }
  }
  dd {
    margin-left: 0;
    @include regular;
  }
  span {
    display: block;
  }
  p,ul,li {
    margin-bottom: 0;
  }
}

.dl-destacat {
  font-size: 0;
  text-align: center;
  margin-bottom: 0;
  div {
    font-size: 1rem;
    margin-bottom: vr(.5);
    @media #{$medium-up} {
      display: inline-block;
      border-right: 1px solid #979797;
      padding: 0 vr(2);
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
      @media #{$medium-up} {
        border-right: 0;
      }
    }
  }
  dt {
    @include typi('small');
    @include semibold;
    text-transform: uppercase;
    @media #{$medium-up} {
      margin-bottom: vr(.25);
    }
  }
  dd {
    @include typi('t1');
    @include light;
    margin-left: 0;
  }
}

.role {
  @include lightitalic;
}

ol {
  padding-left: 0;
  list-style-position: outside;
  margin-left: vr(1.5);
}
main ul {
  list-style: none;
  li {
    padding-left: vr(.5);
    position: relative;
    margin-bottom: vr(.5);
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      display: block;
      left: 0;
      top: .6em;
      position: absolute;
      background-color: $link-color;
      border-radius: 100px;
    }
    ul {
      @include typi(s1);
      margin-top: vr(.5);
      margin-bottom: 0;
      li {
        margin-bottom: vr(.25);
        padding-left: 0;
        list-style: circle;
        margin-left: vr(1.5);
        &:before {
          display: none;
        }
      }
    }
  }
}
.section--2 ul li:before {
  background-color: #fff;
}

// llistat amb klanders quadrats
main ul.squares {
  li {
    &:before {
      border-radius: 0;
    }
  }
}
// llistat sense klanders
ul.simple,
.simple ul {
  @include undolist;
}
::selection {
  color: #fff;
  background-color: $primary-color;
}
strong, b {
  @include semibold;
}
em, i {
  @include italic;
}
hr {
  // border: 1px solid #000;
  // border-width: 1px 0 0;
  // margin: vr(3) auto;
  height: 0;
  // width: vr(5);
    border: 1px solid #e2e2e2;
    border-width: 1px 0 0;
    margin: vr(1.5) auto;
  .section--newsletter &,
  .colab-block & {
    border-color: #fff;
  }
  .colabora & {
    width: vr(5);
    // margin: vr(1.5) auto;
  }
}

// links underline
a {
  color: $link-color;
  text-decoration: none;
  border-bottom: rem-calc(1) solid;
  transition: border-width .1s,;
  .section--2 &,
  .section--4 & {
    color: #fff;
  }
  &:hover {
    border: 0;
  }
  &.img-link {
    border: 0;
  }
}
// main a {
//   text-decoration: none;
//   text-shadow: 0.05em 0 0 #fff, -0.05em 0 0 #fff,
//       0 0.05em 0 #fff, 0 -0.05em 0 #fff,
//       0.1em 0 0 #fff, -0.1em 0 0 #fff,
//       0 0.1em 0 #fff, 0 -0.1em 0 #fff;
//   background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
//   background-repeat: repeat-x;
//   background-position: bottom 0.05em center;
//   background-size: 100% 0.05em;
// }
// .ie-lte-9 a {
//   text-decoration: underline;
// }

.dl-link {
  &:before {
    content: '';
    display: inline-block;
    vertical-align: baseline;
    margin-right: 8px;
  }
}
.lead {
  @include typi(lead);
  //text-align: center;
}

// llistat amb columnes
.cols-list {
  list-style: none;
  @media only screen and (min-width: 30em) {
    column-count: 2;
  }
}

// llistat simple, sense klander
.simple-list {
  margin-left: 0;
  li {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
}

// llistat destacat
.dest-list {
  @include bold;
  a {
    border: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}

// llistat amb filets
.bordered {
  margin-bottom: vr(2);
  li {
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: vr(.5);
    padding-bottom: vr(.5);
    // padding-left: 0;
    // &:before {
    //   display: none;
    // }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  a {
    border: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}

// llistat enllaços
.rel-links {
  li {
    padding-left: 0;
    background-color: #f4f4f4;
    padding: vr(.75) vr(.5);
    position: relative;
    border-bottom: 1px solid #979797;
    padding-left: rem-calc(35);
    color: #757575;
    &:before {
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      margin-right: vr(.5);
      // @include sprite(kland-enllacos-blog);
      left: 15px;
      top: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    a {
      border: 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// llista horitzontal
.inline-list {
  text-align: center;
  font-size: 0;
  li {
    font-size: 1rem;
    display: inline-block;
    margin: 0 vr(.5);
    vertical-align: middle;
    padding-left: 0;
    &:before {
      display: none;
    }
  }
  &--img {
    // li {
    //   margin-bottom: vr(1);
    // }
    margin-bottom: 0;
    li {
      margin: vr(1) vr(.75) 0;
    }
    a {
      border: 0;
      opacity: 1;
      transition: opacity .2s;
      &:hover {
        border: 0 !important;
        opacity: .8;
      }
    }
  }
  &--logos {
    margin-bottom: vr(1);
    @media #{$large-up} {
      margin-bottom: 0;
    }
    li {
      // display: block;
      margin: vr(1);
      @media #{$medium-up} {
        display: inline-block;
        margin: vr(.5) vr(1);
      }
      .home & {
        @media #{$large-up} {
          margin: vr(.75) 1.1rem;
        } 
      }
    }
  }
  &--nav {
    @include typi('big');
    margin-bottom: vr(2);
    li {
      font-size: inherit;
    }
    a {
      border: 0;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
  &--left {
    text-align: left;
    li {
      margin: 0 vr(1.5) 0 0;
    }
  }
}

// filtres
.filter-list {
  .btn {
    text-transform: none;
    margin-bottom: vr(.25);
    width: 100%;
  }
  li {
    margin: 0 vr(.125);
    vertical-align: inherit;
    &:last-child {
      a {
        margin-bottom: 0;
      }
    }
  }
  .current {
    a {
      background-color: $primary-color;
      color: #fff;
    }
  }
}

// block-grid
[class*=block-grid] > li {
  &:before {
    display: none;
  }
}

// intro text
.intro-text {
  @include typi('bigger');
  @include extralight;
  text-align: left;
  margin-bottom: vr(1.5);
  .home & {
    margin-bottom: 0;
  }
}

// text més gran
// .big-txt,
.big-txt p {
  @include typi('big');
}

// uppercase
.uc {
  text-transform: uppercase;
}

// cta
.cta {
  @include typi('bigger');
  text-align: center;
  text-transform: uppercase;
  b {
    display: block;
  }
  a {
    border: 0;
    &:hover {
      text-decoration: underline;
    }
  }
}

// contact
p.contact {
  @include typi('s1');
  // padding-bottom: vr(1);
  padding-left: vr(2.5);
  position: relative;
  &:before {
    content: '';
    display: block;
    @include sprite('ico-contacto');
    position: absolute;
    left: 0;
    top: 0;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: vr(1.75);
    top: 0;
    width: 1px;
    height: 100%;
    background-color: $primary-color;
  }
  // border-bottom: 1px solid #e2e2e2;
  b,strong,a {
    @include typi('s0');
  }
}

h2.contact {
  padding-top: vr(1);
  &:before {
    content: '';
    display: block;
    @include sprite('ico-contacto');
    position: relative;
    left: 0;
    top: -.725rem;
    font-size: .95rem;
  }
}

.num {
  @include typi('bigger');
  @include regular;
  letter-spacing: 2.3px;
}