.expandable {
    summary {
        background-color: #F9F9F9;
        text-align: center;
        padding: 20px;
        border-radius: 8px;
        transition: linear 500ms;
        cursor: pointer;
        border: 1px solid $primary-color;
        text-align: left;
        .investigacion & {
            position: relative;
        }
        p.cat {
            @include semibold;
            margin-bottom: 5px;
            a {
                border-bottom: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        h2 {
            font-size: 18px;
            @include semibold;
            max-width: 90%;
        }
    }
    summary::-webkit-details-marker {
        display: none
    }
    summary:after {
        content: "\25bc";
        color: $primary-color;
        font-size: 85%;
        margin-right: 2px;
        transitions: all 500ms;
        -webkit-transitions: all 500ms;
        float: right;
        .investigacion & {
            position: absolute;
            right: 40px;
            top: 50px;
        }
    }
    details[open] summary:after {
        content: "\25b2";
        color: #0060ac;
    }
    summary:focus {
        outline-style: none;
    }
    article>details>summary {
        font-size: 28px;
        margin-top: 16px;
    }
    details {
        margin-bottom: 20px;
    }
    details div {
        padding: 40px;
        margin: 10px 0 15px;
        background-color: #f0f6fa;
    }
    details>p {
        margin-left: 24px;
    }
    details details {
        margin-left: 36px;
    }
    details details summary {
        font-size: 16px;
    }
}