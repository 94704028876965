.sidebar-left {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    & > :first-child {
        flex-grow: 1;
        flex-basis: 15rem;
    }
    & > :last-child {
        flex-basis: 0;
        flex-grow: 999;
        min-width: 50%;
        > * + * {
            margin-top: 20px;
        }
    }
}
.sidebar-right {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    & > :first-child {
        flex-basis: 0;
        flex-grow: 999;
        min-width: 50%;
        > * + * {
            margin-top: 20px;
        }
    }
    & > :last-child {
        flex-grow: 1;
        flex-basis: 15rem;
    }
}
