.enllac-extern {
    
    @include typi(s1);
    @include bold;
    text-transform: uppercase;
    color: #fff;
    text-decoration: underline;
    position: absolute;
    left: 50%; 
    bottom: vr(1);
    text-align: center;
    text-decoration: none;
    position: absolute;
    margin-left: -38px;
    border: 0;
    transition: opacity .1s;
    z-index: 1; 
    @media #{$large-up} {
      display: block;
      &:hover {
        opacity: .8;
      }
    } 
  }
  