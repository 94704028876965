.slider__overlay {
	background-color: rgba(#000,.425);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.slick-dotted.slick-slider {
    margin-bottom: 50px;
}
.home-slider-mobile{
	text-align: center;
	overflow: hidden;
	background-size: cover;
	background-position: 0 0;
	position:relative;
	min-height: rem-calc(400);
	max-height: rem-calc(600);
	height: 100vh;
	.item {
		position: relative;
		min-height: rem-calc(400);
		height: 100vh;
		background-color: #555;
		max-height: rem-calc(600);
		background-size: cover;
		background-position: 50% 0;
		padding-top: rem-calc(106);
		.slider__caption {
			padding: 0 vr(.75);
			z-index:0;
			margin-top:0;
			p{
				font-size: rem-calc(22);
				margin-bottom: 0;
				line-height: 1.5;
				@media (min-width: 375px){
					font-size: rem-calc(26);
				}
			}
			.btn{
				margin-top:rem-calc(30);
			}
		}

	}
}
.slider {
	text-align: center;
	overflow: hidden;
	background-size: cover;
	background-position: 0 0;
	position:relative;
	background-color: #555;
	// @media #{$large-up} {
	// 	min-height: 100vh;
	// }
	min-height: rem-calc(450);
	height: rem-calc(450);
	@media #{$medium-up} {
        min-height: rem-calc(478);
        height: rem-calc(478);
    }
    @media #{$large-up} {
        min-height: rem-calc(700);
        height: rem-calc(700);
    }
    
    .slide {
        background-size: cover;
        min-height: rem-calc(450);
	    height: rem-calc(450);
	    @media #{$medium-up} {
	        min-height: rem-calc(478);
	        height: rem-calc(478);
	    }
	    @media #{$large-up} {
	        min-height: rem-calc(700);
	        height: rem-calc(700);
	    }
    }

    div:not(:first-child) {
        display:none;
    }
    &.slick-initialized {
        div {
            display:block;
        }
    }
/* 	.slick-list { */
/* 		z-index: -1; */
/* 	} */

	// contenidor imatge
	.slick-slide {
		position: relative;
		height: rem-calc(450);
		background-color: #555;
		background-size: cover;
		background-position: 50% 0;
		padding-top: rem-calc(106);
		@media #{$medium-up} {
			height: rem-calc(480);
			padding-top: 0;
		}
		@media #{$large-up} {
			height: rem-calc(700);
			// min-height: 100vh;
			padding-top: 0;
		}
	}

	// fletxes
	.slick-prev,
	.slick-next {
		width: 20px;
		height: 37px;
		margin-top: rem-calc(53);
		@media #{$medium-up} {
			width: 27px;
			height: 50px;
			margin-top: rem-calc(61);
		}
		@media #{$large-up} {
			margin-top: 0;
		}
		&:before {
			content: '';
			display: block;
			font-size: .75rem;
			@media #{$medium-up} {
				font-size: 1rem;
			}
		}
		&:hover,
		&:focus {
			&:before {
				opacity: .8;
			}
		}
	}
    .slick-prev {
        left: vr(.5);
        z-index: 1;
        .js-menu-open & {
            z-index: 0;
        }
        @media #{$medium-up} {
            left: vr(1);
        }
        &:before {
            @include sprite('fletxa-esq');
        }
    }
	.slick-next {
		right: vr(.5);
		@media #{$medium-up} {
			right: vr(1);
		}
		&:before {
			@include sprite('fletxa-dreta');
		}
	}

	// caption
	&__caption {
		text-align: center;
		position: absolute;
		padding: 0 vr(1.75);
		z-index: 1;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
		margin-top: rem-calc(53);
		> *:last-child {
			margin-bottom: 0;
		}
		@media #{$medium-up} {
			width: 75%;
			margin-top: rem-calc(61);
		}
		@media #{$large-up} {
			width: 60%;
			margin-top: rem-calc(21);
		}
		&--left,
		&--right {
			@media #{$medium-up} {
				padding: 0;
				width: 52%;
				transform: translate(0,-50%);
			}

			@media #{$xlarge-up} {
				width: 40%;
			}
			@media only screen and (min-width: 1550px) {
				width: 37%;

			}
			@media only screen and (min-width: 1720px) {
				width: 34%;
			}
			@media only screen and (min-width: 1850px) {
				width: 32%;
			}
			@media only screen and (min-width: 1920px) {
				width: 30%;
			}
		}
		&--left {
			@media #{$medium-up} {
				left: vr(3.5);
				text-align: left;
			}
			@media #{$large-up} {
				left: vr(6);
			}
		}
		&--right {
			@media #{$medium-up} {
				right: vr(3.5);
				left: auto;
				text-align: right;
			}
			@media #{$large-up} {
				right: vr(6);
			}
		}
		p {
			@include typi('slider');
			margin-bottom: vr(.5);
			color: #fff;
			// text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
			text-shadow: 0 2px 3px rgba(0,0,0,0.4);
			a {
				color: #fff;
			}
		}

		.panel {
			color: $body-font-color;
			background-color: #fff;
			background-color: rgba(#fff,.9);
			padding: vr(.5);
			@media #{$medium-up} {
				padding: vr(1);
			}
			p {
				color: $body-font-color;
				text-shadow:none;
			}
			a {
				color: $body-font-color;
				border-bottom: 0;
				&:hover {
					border-bottom: 1px solid;
				}
			}
			.btn {
				background-color: $primary-color;
				color: #fff;
				border: 2px solid $primary-color;
				&:hover {
					background-color: #fff;
					color: $primary-color;
					border-bottom-width: 2px;
				}
			}
		}
	}

	// botons navegació
	.slick-dots {
		bottom: vr(.25);
		@media #{$medium-up} {
			bottom: vr(.5);
		}
		@media #{$medium-up} {
			bottom: vr(.75);
		}
		@media #{$large-up} {
			bottom: vr(1);
			text-align: right;
		}
		li {
			margin-left: 0;
			margin-right: 0;
			width: vr(1);
			height: vr(1);
			@media #{$medium-up} {
				width: vr(2);
				height: vr(2);
			}
			@media #{$large-up} {
				margin-left: vr(.5);
				margin-right: vr(.5);
				width: vr(1);
				height: vr(1);
			}
			&:before {
				display: none;
			}
			button {
				padding: 0;
				width: vr(1);
				height: vr(1);
				@media #{$medium-up} {
					width: vr(2);
					height: vr(2);
				}
				@media #{$large-up} {
					width: vr(1);
					height: vr(1);
				}
				&:before {
					width: vr(.5);
					height: vr(.5);
					left: vr(.25);
					top: vr(.25);
					border: 1px solid #fff;
					@media #{$medium-up} {
						width: vr(1);
						height: vr(1);
						left: vr(.5);
						top: vr(.5);
						border-width: 2px;
					}
					@media #{$large-up} {
						left: 0;
						top: 0;
					}
				}
			}
			&.slick-active button:before {
				background-color: #fff;
			}
		}
	}
}
.slider-logos{
	max-height: rem-calc(140);
	overflow: hidden;
	padding: rem-calc(0 20);
	.slick-slide img {
	    margin: 0 auto;
	}
	.slick-prev,
	.slick-next {
		width: 20px;
		height: 37px;
		margin-top: rem-calc(-20);
		transform: scale(.7);
		&:before {
			content: '';
			display: block;
			font-size: .75rem;
		}
		&:hover,
		&:focus {
			&:before {
				opacity: .8;
			}
		}
	}
    .slick-prev {
        left: 0;
        z-index: 1;
        .js-menu-open & {
            z-index: 0;
        }
        &:before {
            @include sprite('fletxa-esq-negre');
        }
    }
	.slick-next {
		right: 0;
		&:before {
			@include sprite('fletxa-dreta-negre');
		}
	}
}
