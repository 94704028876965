.s-mobile {
  color: #fff;
  margin-bottom: vr(1);
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
  label {
    @include visually-hidden;
  }
  button {
    background: transparent;
    padding: 0;
    margin-left: 10px;
  }
  input {
    padding: 0 .5em;
    line-height: 31px;
    color: $body-font-color;
    -webkit-appearance: none;
  }
  @media #{$large-up} {
    display: none;
  }
}
.s-button {
  display: none;
  background: transparent;
  position: absolute;
  top: 0;
  right: $column-gutter*.5;
  @media #{$large-up} {
    position: static;
    display: inline-block;
    vertical-align: middle;
    margin-left: vr(.5);
    .sticky & {
      display: none;
    }
  }
}


// Search desktop

.s-desktop {
  display: none;
  @media #{$medium-up} {
    display: inline-block;
    vertical-align: middle;
    height: rem-calc(30);
    margin-left: vr(.5);
  }
  @media #{$large-up} {
    height: rem-calc(39);
  }
  .sticky-header & {
    display: none;
  }
}

[role="search"] { // http://adrianroselli.com/2015/12/responsive-progressive-accessible-vanilla-search.html
  // box-sizing: border-box;
  border-radius: 4px;
  // border: 1px solid transparent;
  padding: 0;
  margin: 0;
  /*max-width: 36em;*/
  // line-height: 1.6;
  display: inline-block;
  // line-height: 0;
  // transition: all .2s ease-in;
  background-color: #fff;
  // border: 1px solid rgba(0, 0, 0, .5);
  width: 100%;
  height: rem-calc(30);
  @media #{$large-up} {
    height: rem-calc(39);
  }
}

[role="search"].notempty {
  background-color: #fff;
  // border: 1px solid rgba(0, 0, 0, .5);
}

[role="search"]::after {
  content: "";
  display: block;
  clear: both;
}

[role="search"] label {
  display: inline-block;
  overflow: hidden;
  margin: 0;
  // padding: 0 0 .5em 0;
  float: left;
    float: right;
  cursor: pointer;
  // transition: all .2s ease-in;
  // height: 1.5em; /* for IE */
  text-indent: -1000px;
  width: 0;
}

[role="search"] label img {
  // fill: #fff;
  // width: 1.5em;
  // margin-top: .5em; /* Must go away for IE. Ugh. */
  // transition: all .2s ease-in;
  margin: 4px 5px 0 0;
  height: 20px;
  @media #{$large-up} {
    height: auto;
  }
}

// [role="search"] input[type="search"]:focus ~ label svg,
// [role="search"].notempty label svg {
//   fill: transparent;
// }

[role="search"] input[type="search"] {
  border: none;
  // border-radius: .5em;
  background-color: transparent;
  margin: 0;
  padding: .5em .75em;
  width: 65%;
  width: calc(100% - 40px);
  // transition: all .2s ease-in;
  box-sizing: border-box;
}

[role="search"] input[type="search"]:focus {
  outline: none;
  background-color: #fff; /* Needed for non-JS */
}

input::-webkit-input-placeholder {
  color: #757575; /* a11y FTW! */
}

input:-ms-input-placeholder {
  color: #757575; /* a11y FTW! */
}

input::-moz-placeholder {
  color: #757575; /* a11y FTW! */
  opacity: 1; /* https://www.quirksmode.org/blog/archives/2015/12/styling_placeho.html */
}

[role="search"] button {
  // font-size: 90%; /* This allows the width in ems to scale  */
  // width: 2em;
  // height: auto;
  margin: 0;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  // transition: all .2s ease-in;
  float: right;
}

[role="search"] button:hover,
[role="search"] button:focus {
  outline: none;
}

[role="search"] input[type="search"]:focus ~ button,
[role="search"].notempty button {
  float: right;
  text-indent: 0;
  width: 31px;
  display: block;
    margin: 3px 5px 0 0;
}

[role="search"] button img {
  display: block;
  // margin: 0 auto;
  // padding-right: .2em;
  // fill: #757575;
  //width: 100%;
  // transition: all .2s ease-in;
    margin: 6px;
  height: 20px;
  @media #{$large-up} {
    height: auto;
  }
}

// [role="search"] button:hover svg,
// [role="search"] button:focus svg {
//   fill: #00f;
// }

// [role="search"] input[type="search"]:focus ~ button svg,
// [role="search"].notempty button svg {
//   /*height: 100%;*/
//   /*width: 4em;*/
// }

// @media all and (min-width: 24em) {

  [role="search"] {
    background-color: transparent;
    // border: 1px solid rgba(0, 0, 0, 0);
    width: auto;
  }

  [role="search"] label {
    text-indent: 0;
    width: auto;
  }

  [role="search"] input[type="search"]:focus ~ label,
  [role="search"].notempty label {
    text-indent: -1000px;
    width: 0;
  }

  [role="search"] input[type="search"] {
    width: .01em; /* Must have min width or Safari won't work*/
    padding: 0;
    float: left;
  }

  /*[role="search"] label:matches(:hover, :focus) /for/ input, CSS Selectors 4, 13.5. Reference combinators http://www.w3.org/TR/selectors4/#idref-combinators, no support */
  [role="search"] input[type="search"]:focus,
  [role="search"].notempty input[type="search"] {
    padding: 0 0 0 .75em;
    width: 65%;
    width: calc(100% - 40px);
    margin-top: 3px;
    @media #{$large-up} {
      margin-top: 6px;
    }
  }

  [role="search"] button {
    display: none;
    width: 0;
    text-indent: -1000px;
  }

  // [role="search"].notempty button:hover svg,
  // [role="search"].notempty button:focus svg {
  //   fill: #00f; Color for when JS fires
  // }

  // [role="search"] button:hover svg,
  // [role="search"] button:focus svg {
  //   fill: #fff; /* Color for non-JS */
  // }

// }

// resultats
.list.cercador{
  article{
     padding-bottom: rem-calc(15);
    &:last-child {
      padding-bottom: rem-calc(30);
    }
    h2{
        margin:0 0 .25rem;
        line-height: 1;
        a{
          text-decoration: none;
          border:none;
          font-size: rem-calc(20);
          &:hover{
            text-decoration: underline;
          }
        }
    }
  }

}