.columns2 > .column,
.columns3 > .column {
    > * + * {
        margin-top: 15px;
    }
}

// overwrite bulma styles
.columns:not(:last-child) {
    margin-bottom: 0;
}