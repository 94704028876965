.lists-container {
  h2,h3 {
    @include typi('big');
    @include semibold;
    margin-bottom: vr(.5);
    padding-top: 0;
  }
  ul {
  	// @include undolist;
    margin-bottom: vr(1.5);
  }
  li {
    margin-bottom: vr(.25);
  }
  a {
  	border: 0;
    color: $body-font-color;
  	&:hover {
  		border-bottom: 1px solid;
  	}
  }
}