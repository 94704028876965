.sub-hd {
  // margin-top: vr(-1);
  margin-bottom: vr(1.5);
  text-align: center;
  @media #{$medium-up} {
    // margin-top: vr(-1.5);
    text-align: left;
  }
  @media #{$large-up} {
    // margin-top: vr(-2);
    margin-bottom: vr(3);
  }
  &--simple {
    margin-bottom: 0;
  }
  &--img {
    //padding-bottom: vr(22);
    background: no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    background-color: #333;
    @media #{$medium-up} {
     //padding-bottom: vr(30);
    }
    @media #{$large-up} {
     // padding-bottom: vr(45);
     // padding-bottom: vr(40);
     //padding-bottom: 0;
     height: 260px;
     //background-attachment: fixed;
    }
    .iis & {
      margin-bottom: 0;
      background-image: url('img/quienes-somos-small.jpg');
      @media #{$retina} {
        background-image: url('img/quienes-somos-small@2x.jpg');
      }
      @media #{$medium-up} {
       background-image: url('img/quienes-somos-medium.jpg');
       @media #{$retina} {
         background-image: url('img/quienes-somos-medium@2x.jpg');
       }
      }
      @media #{$large-up} {
       background-image: url('img/quienes-somos-large.jpg');
       @media #{$retina} {
         background-image: url('img/quienes-somos-large@2x.jpg');
       }
      }
    }
    .investigacion & {
      // margin-bottom: 0;
      background-image: url('img/investigacion-small.jpg');
      @media #{$retina} {
        background-image: url('img/investigacion-small@2x.jpg');
      }
      @media #{$medium-up} {
       background-image: url('img/investigacion-medium.jpg');
       @media #{$retina} {
         background-image: url('img/investigacion-medium@2x.jpg');
       }
      }
      @media #{$large-up} {
       background-image: url('img/investigacion-large.jpg');
       @media #{$retina} {
         background-image: url('img/investigacion-large@2x.jpg');
       }
      }
    }
    .innovacion & {
      margin-bottom: 0;
      background-image: url('img/innovacion-small.jpg');
      @media #{$retina} {
        background-image: url('img/innovacion-small@2x.jpg');
      }
      @media #{$medium-up} {
       background-image: url('img/innovacion-medium.jpg');
       @media #{$retina} {
         background-image: url('img/innovacion-medium@2x.jpg');
       }
      }
      @media #{$large-up} {
       background-image: url('img/innovacion-large.jpg');
       @media #{$retina} {
         background-image: url('img/innovacion-large@2x.jpg');
       }
      }
    }
    .servicios & {
      background-image: url('img/servicios-small.jpg');
      @media #{$retina} {
        background-image: url('img/servicios-small@2x.jpg');
      }
      @media #{$medium-up} {
       background-image: url('img/servicios-medium.jpg');
       @media #{$retina} {
         background-image: url('img/servicios-medium@2x.jpg');
       }
      }
      @media #{$large-up} {
       background-image: url('img/servicios-large.jpg');
       @media #{$retina} {
         background-image: url('img/servicios-large@2x.jpg');
       }
      }
    }
    // .formacion & {
    //   background-image: url('img/formacion-small.jpg');
    //   @media #{$retina} {
    //     background-image: url('img/formacion-small@2x.jpg');
    //   }
    //   @media #{$medium-up} {
    //    background-image: url('img/formacion-medium.jpg');
    //    @media #{$retina} {
    //      background-image: url('img/formacion-medium@2x.jpg');
    //    }
    //   }
    //   @media #{$large-up} {
    //    background-image: url('img/formacion-large.jpg');
    //    @media #{$retina} {
    //      background-image: url('img/formacion-large@2x.jpg');
    //    }
    //   }
    // }
  }
  &--solid {
    background-color: $primary-color;
  }
  &--grad {
    background-color: $primary-color;
    background-image: linear-gradient(to left, #141C1F, #0056a4);
  }
  &--grad-light {
    background-image: linear-gradient(to left, rgba(0, 143, 209, 0.0), #0056a4);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0056a4', endColorstr='#00008fd1',GradientType=1 ); /* IE6-9 */
  }
  &__bg {
    padding: vr(1) 0;
    .sub-hd--img & {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: 260px;
      background-image: linear-gradient(to left, rgba(0, 143, 209, 0.0), #008CCE);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008CCE', endColorstr='#00008fd1',GradientType=1 ); /* IE6-9 */
      .blog & {
        background-image: linear-gradient(to left, rgba(0, 143, 209, 0.0), #CE6600);
      }
      @media #{$medium-up} {
        //top: rem-calc(123);
      }
      @media #{$large-up} {
        //top: rem-calc(169);
        // opacity: 1;
        // transition: opacity .2s ease-out, top .2s ease-out;
        // .sticky-header & {
        //   opacity: 0;
        //   top: rem-calc(50);
        // }
      }
    }
    .sub-hd--simple & {
      padding-top: 0;
    }
    @media #{$large-up} {
      padding: 2.5rem 0 vr(1.5);
    }
    .js-submenu-open & {
      bottom: 0;
    }
  }
}
.introFeines{
  border-right: 2px solid #979797;
  p{
    text-align: left;
  }
  img{
    float: left;
    margin-right: 1em;
  }
}
.introFeines2 {
  p{
    text-align: left;
    margin-left: rem-calc(10);
     @media #{$medium-up} {
      margin-left: rem-calc(20);
    }
  }
}
.apartat {
  @include typi('apartat');
  @include extralight;
  display: block;
  //text-transform: uppercase;
  //text-align: center;
  .sub-hd--img &,
  .sub-hd--solid &,
  .sub-hd--grad & {
    color: #fff;
  }

  &.feines{
    margin-bottom: rem-calc(20);
  }
  @media #{$medium-up} {
    margin-bottom:  vr(1);
  }
}
span.apartat {
  @include black;
  font-size: 52px;
  margin-bottom: .5rem;
}
h1.apartat, p.apartat {
  font-size: 32px;
  @include extralight;
  @media #{$medium-up} {
    text-align: left;
  }
  .subportada & {
    @include black;
    font-size: 52px;
    text-transform: none;
    margin-bottom: 1rem;
  }
}