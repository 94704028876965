.event-item {
  padding: vr(.25) vr(1) vr(1);
  background-color: #e9e9e9;
  margin-top: vr(.25);
  border-radius: 8px;
  @media #{$medium-up} {
    min-height: vr(12);
  }
  @media #{$large-up} {
    margin-top: 0;
    .row--last & {
      min-height: vr(15);
      margin-top: vr(1);
    }
  }
  &__date {
    @include semibold;
    @include typi('event-date');
    text-transform: uppercase;
    color: $primary-color;
    // margin-bottom: vr(.25);
    display: block;
    span {
      @include black;
      @include typi('biggest');
      letter-spacing: -3px;
    }
  }
  &__categ {
    @include semibold;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 11px;
    a {
      border: 0;
      color: #E51A9E;
      border-bottom: 1px solid transparent;
      &:hover {
        border-bottom: 1px solid #E51A9E;
      }
      .actualidad & {
        margin-bottom: 5px;
        display: inline-block;
      }
      .tags & {
        text-transform: none;
      }
    }
  }
  &__title {
    padding-top: 0 !important;
    margin-bottom: 30px;
    @include typi('t4');
    .view & {
      text-align: left;
    }
    a {
      color: #000;
      border-bottom: 0;
      &:hover {
        border-bottom: 1px solid;
      }
    } 
  }
  &__loc,
  &__time {
    @include typi('s1');
    @include regular;
    color: $primary-color;
    font-size: 1rem;
    padding-left: 18px;
    position: relative;
    margin-bottom: 0;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      vertical-align: middle;
      left: 0;
      top: .4em;
    }
  }
  &__loc:before {
    @include sprite('icon-loc');
  }
  &__time:before {
    @include sprite('icon-time');
  }
  &__img {
    margin-bottom: 0;
    margin-top: vr(.5);
    @media #{$large-up} {
      margin-top: 0;
      .item-narrow & {
        margin-top: vr(.5);
      }
    }
    img {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
  }
  &__container {
   @media #{$large-up} {
     padding-left: vr(3);
   }
 }
  
  // versió pel llistat d'events
  &--simple {
    background-color: transparent;
    border-bottom: 1px solid #979797;
    padding: 0 0 vr(1);
    margin: 0 0 vr(1);
    .view & {
      border-bottom: 0;
      padding-bottom: 0;
    }
    @media #{$medium-up} {
      min-height: auto;
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
    .event-item__title {
      @include typi('t2');
      @include bold;
      margin-bottom: vr(1);
    }
    .event-item__info {
      background-color: #ebebeb;
      padding: vr(1);
      @media #{$large-up} {
        padding-left: vr(3);
      }
    }
    .event-item__date,
    .event-item__categ,
    .event-item__title {
      @media #{$large-up} {
        padding-left: vr(3);
      }
    }
  }
}

.brdr-container {
  @media #{$medium-up} {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: rem-calc(-60);
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #a6a6a6;
    }
  }
}