.top {
  display: none;
  img{
    width: vr(2);
    height: vr(2);
    margin-top: vr(-1);
    margin-bottom: vr(1);
  }
  @media #{$medium-up} {
    position: fixed;
    right: vr(1);
    bottom: vr(1);
    transition: transform .2s;
    z-index: 2;
    text-align: right;
    border: 0;

    width: vr(4);
    height: vr(4);
  }
  @media #{$large-up} {
    width: auto;
    height: auto;
  }
  &:hover,
  &:focus {
    border: 0 !important;
  }
}
