.responsable {
  @media #{$large-up} {
    text-align: center;
    position: relative;
    max-height: 650px;
    min-height: 400px;
    overflow: hidden;
  }
  img {
    margin-bottom: vr(1); 
  }
  .row {
    @media #{$large-up} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
    }
  }
  .column {
    // text-align: center;
    @media #{$medium-up} {
      @include grid-column(
        $columns: 6,
        $center: true
      );
    }
    @media #{$large-up} {
      height: 100%;
      @include grid-column(
        $center: false
      );
    }
  }
  &--r {
    .column {
      @media #{$large-up} {
        @include grid-column(
          $float: right
        );
      }
    }
  }
  &__info {
    @include typi('s0');
    @include semibold;
    @media #{$large-up} {
      text-align: left;
      height: 100%;
      background-color: #fff;
      background-color: rgba(#fff,.8);
      //padding: vr(2) vr(2) vr(1);
    }
    p {
      @include typi('big');
      margin-bottom: vr(.25);
    }
  }
  &__title {
    @include typi('big');
    @include semibold;
    margin-bottom: vr(.25);
  }
  &__name {
    @include light;
    @include typi('t1');
    padding-top: 0 !important;
    margin-bottom: vr(.25);
  }
  &__role {
    @include lightitalic;
  }
  .btn {
    margin-top: vr(.5);
    @media #{$small-only} {
      color: #fff;
      background-color: $primary-color;
    }
    @media #{$medium-only} {
      color: #fff;
      background-color: $primary-color;
    }
    @media #{$large-only} {
      color: #fff;
      background-color: $primary-color;
    }
  }
  dl {
    @media #{$large-up} {
      margin-bottom: 0;
    }
  }
}

.coordinador {
  background-color: #efefef;
  margin-bottom: vr(3);	
  @media #{$large-up} {
    text-align: center;
    position: relative;
    max-height: 650px;
    min-height: 478px;
    overflow: hidden;
    &.noimage{
        min-height: auto;
        
        .coordinador__info {
            padding: vr(2) vr(0);
        }
    }
  }
  
  @media #{$medium-up} {
      img {
         text-align: right;
      }    
  }
  &__info {
    @include typi('s0');
    @include semibold;
    padding: vr(1) 0 vr(1);
    max-width:  411px;
    margin: auto;
    @media #{$medium-up} {
      padding: vr(3) vr(0) vr(1);    
    }
    @media #{$large-up} {
      text-align: left;
      height: 100%;
      padding: vr(4) vr(0) vr(1);
    }
    p {
      @include typi('big');
      margin-bottom: vr(.25);
    }
  }
  &__title {
    @include typi('big');
    @include semibold;
    margin-bottom: vr(.25);
  }
  &__name {
    @include light;
    @include typi('t1');
    padding-top: 0 !important;
    margin-bottom: vr(.25);
  }
  &__role {
    @include lightitalic;
  }
}