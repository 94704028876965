.btn {
  @include typi(btn);
  @include semibold;
  display: inline-block;
  text-decoration: none;
  margin-bottom: vr(1);
  padding: vr(.3) vr(1);
  text-transform: uppercase;
  text-align: center;
  background-color: #fff;
  border: 2px solid $primary-color;
  letter-spacing: .1px;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  border-radius: 4px;
  // @media #{$medium-up} {
  //   width: auto;
  // }
  // &:hover,
  // &:focus {
  //   background-color: #fff;
  //   color: $primary-color;
  //   // border-color: $primary-color !important;
  //   text-decoration: none !important;
  //   border: 1px solid $primary-color !important;
  // }
  &:hover,
  &:focus {
    border: $primary-color solid 2px;
    background-color: $primary-color;
    color: #fff;
  }
  .section--6 & {
    background-color: transparent;
    border: 2px solid #fff;
  }
  &--medium {
    @include typi(btn-med);
    padding: vr(.25) vr(3);
  }

  &--big {
    @media #{$medium-up} {
      @include typi(btn-big);
      padding: vr(.4) vr(1.5);
    }
    @media #{$large-up} {
      padding-top: vr(.75);
      padding-bottom: vr(.75);
    }
  }

  &--neg {
    background-color: transparent;
    border-color: #fff;
    border-width: 1px;
    color: #fff;
    &:hover,
    &:focus {
      background-color: #fff;
      color: $primary-color;
      border: 1px solid #fff;
    }
  }
  &--disabled {
		color: #a4a4a4;
		border-color: #a4a4a4;
		cursor: not-allowed;
  }

  &--solid {
    color: $body-font-color;
    border-color: #fff;
  }

  &--solid-2 {
    color: #fff;
    border-color: $primary-color;
    background-color: $primary-color;
    &:hover,
    &:focus {
      background-color: #fff;
      color: $primary-color;
      // border-color: $primary-color;
    }
  }

  &--colab {
    color: #fff;
    background-color: #3ACAC0;
    //background-image: linear-gradient(to bottom, #008fd1, #0056a4);
    width: auto;
    border: 1px solid #3ACAC0 !important;
    position: absolute;
    right: $column-gutter/2;
    bottom: vr(1);
    margin-bottom: 0;
    transition: padding .2s;
    border-radius: 8px;
    font-size: 11px;
    display: none;
    &:hover,
    &:focus {
      background: $primary-color;
      border: 1px solid $primary-color !important;

    }
    @media #{$medium-up} {
      // bottom: auto;
      // top: 0;

        position: static;
        display: inline-block;
        vertical-align: middle;
        margin-left: vr(.5);
    }
    @media #{$large-up} {
      // position: static;
      // display: inline-block;
      // vertical-align: middle;
      // margin-left: vr(.5);
      padding: .7rem 1.6rem .6rem;
      .sticky & {
        display: none;
      }
    }
  }
  &--w {
    color: #000;
    background-color: transparent;
    //background-image: linear-gradient(to bottom, #008fd1, #0056a4);
    width: auto;
    position: absolute;
    right: $column-gutter/2;
    bottom: vr(1);
    margin-bottom: 0;
    transition: padding .2s;
    border: 1px solid #DFDFDF !important;
    border-radius: 8px;
    font-size: 11px;
    display: none;
    .home & {
      color: #fff;
    }
    &:hover,
    &:focus {
      background: $primary-color;
      border: 1px solid $primary-color !important;
    }
    @media #{$medium-up} {
      // bottom: auto;
      // top: 0;

        position: static;
        display: inline-block;
        vertical-align: middle;
        margin-left: vr(.5);
    }
    @media #{$large-up} {
      // position: static;
      // display: inline-block;
      // vertical-align: middle;
      // margin-left: vr(.5);
      padding: .7rem 1.6rem .6rem;
      .sticky & {
        display: none;
      }
    }
  }
  &--w-b {
    color: #fff;
    background-color: transparent;
    width: auto;
    bottom: vr(1);
    margin-bottom: 0;
    transition: padding .2s;
    border: 1px solid #DFDFDF !important;
    border-radius: 8px;
    font-size: 11px;
    &:hover,
    &:focus {
      background: $primary-color;
      border: 1px solid $primary-color !important;
    }

    @media #{$large-up} {
      padding: .7rem 1.6rem .6rem;
    }
  }
  &--donar {
    background-color: #0056a4;
    background-image: linear-gradient(to left, #37e2a9, #0056a4);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0056a4', endColorstr='#37e2a9',GradientType=1 ); /* IE6-9 */
    color: #fff;
    border: 0;
    @include bold;
    @include typi('t2');
    // margin-bottom: vr(3);
    padding-top: vr(.75);
    padding-bottom: vr(.75);
    &:hover,
    &:focus {
      border: 0;
      background-image: none;
    }
  }
}
.js-inscr-btn {
  margin-top: vr(1);
}
.btn--innov {
  max-width: 260px;
  //margin-top: -50px;
}
