.main-header {
	padding: vr(1) 0;
	margin-bottom: 0;
	background-color: #fff;
	position: relative;

	.colabora & {
		margin-bottom: 0;
	}

	.subhome & {
		margin-bottom: 0;
		//position: absolute;
		width: 100%;
		z-index: 1;
		top: 0;
		@media #{$large-up} {
			//top: vr(-.5);
		}
	}
	.home & {
		margin-bottom: 0;
		position: absolute;
		width: 100%;
		z-index: 1;
		top: 0;
        background:transparent;
		@media #{$large-up} {
			top: vr(-.5);
		}
	}
	.home & {
		@media #{$medium-up} {
			background-color: transparent;
		}

	}
	.js-menu-open & {

		//background-color: #333;
		//background-color: rgba(#000,.9);

	}
	@media #{$medium-up} {
		margin-bottom: 0;
		min-height: rem-calc(118);
		.colabora & {
			margin-bottom: 0;
		}
	}
	@media #{$large-up} {
		text-align: right;
		padding: 1.5rem 0 1rem 0;
		transition: top .2s;

		&.sticky {
			position: fixed;
			width: 100%;
			z-index: 2;
			min-height: auto;
			padding: vr(1) 0;
			top: 0;
			background-color: #fff;

				// height: rem-calc(82);
		}
		.colabora & {
			margin-bottom: vr(-.5);
		}
	}
	.column {
		position: static;
		// @media #{$medium-up} {
		// 	position: relative;
		// }
	}

	&__tools {
        display: none;
		@media #{$medium-up} {
            display: block;
			text-align: right;
		}
	}
}
.site-title {
	width: 98px;
	float: left;
	margin: 0;
	@media #{$medium-up} {
		width: 118px;
		position: absolute;
		top: vr(1);
		left: $column-gutter/2;
	}
	@media #{$large-up} {
		width: auto;
		position: relative;
		left: 0;
		top: 10px;
		z-index: 1;
		padding-right: vr(1);
		//border-right: 1px solid #fff;
		.sticky & {
			padding-right: 0;
			border-right: 0;
		}
	}
	a {
		text-decoration: none;
		border: 0;
	}
}
.logo {
    margin-top: 8px;
	.sticky & {
		display: none;
	}
}
.logo--sticky {
	display: none;
	.sticky & {
		display: block;
		//margin-left: 40px;
		margin-top: -10px;
	}
}
