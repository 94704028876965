.pagination-centered {
  text-align: center;
  margin-top: vr(1);
}
ul.pagination {
  margin-left: 0;
  list-style: none;
  font-size: 0;
  @include undolist;
  // padding-bottom: vr(.5);
  // border-bottom: 1px solid #979797;
  .section--2 & {
    border-bottom-color: #fff;
  }
  li {
    @include typi('s0');
    display: inline-block;
    margin-bottom: 0;
    &.arrow a {
      // width: auto;
      background-color: transparent;
      .section--2 & {
        color: #fff;
      }
      &:hover {
        border-bottom: 1px solid;
        background-color: $primary-color;
        color: #fff;
        .section--2 & {
          background-color: transparent;
        }
      }
    }
    &.prev a {
      &:before {
        content: '\2190 ';
        display: inline;
      }
    }
    &.next a {
      &:after {
        content: ' \2192';
        display: inline;
      }
    }
    &.unavailable a {
      cursor: default;
      color: #979797;
      .section--2 & {
        color: #fff;
        opacity: .6;
      }
      &:hover {
        border-bottom: none;
        color: #979797;
        background-color: transparent;
        .section--2 & {
          color: #fff;
          opacity: .6;
        }
      }
    }
    &.current a {
      background-color: $primary-color;
      color: #fff;
      .section--2 & {
        background-color: #333;
      }
    }
  }
  a {
    border-bottom: none;
    background-color: #ebebeb;
    width: vr(1.25);
    height: vr(1.25);
    line-height: vr(1.25);
    display: block;
    margin: 0 vr(.25) vr(.5);
    .section--2 & {
      background-color: #fff;
      color: $link-color;
    }
    &:hover {
      background-color: $primary-color;
      color: #fff;
      .section--2 & {
        background-color: #333;
      }
    }
  }
}
