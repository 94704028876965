$body-bg: #fff;
$body-font-color: #000;
$primary-color: #0060ac;
// $secondary-color: #eeeed4;
//$link-color: #0084c8;
$link-color: #0D6BA0;
// $link-color: $primary-color;
$open-color: #5e9c1b;
$closed-color: red;

$error: red;
$success: #5e9c1b;