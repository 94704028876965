.alert-box {
  margin-bottom: vr(1.5);
  padding: vr(1);
  background-color: #fff;
  color: $body-font-color;
  border: 2px solid;
  border-radius: 6px;
  >*:last-child {
    margin-bottom: 0;
  }
  p {
    margin-bottom: vr(.5);
  }
  &.success {
    border-color: $success;
  }
  &.error {
   border-color: $error; 
  }
}