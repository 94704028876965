// webfonts declarations
@font-face {
    font-family: 'TitilliumWeb-ExtraLight';
    src: url('/media/fonts/titillium-web/Titillium-Web-ExtraLight.eot');
    src: url('/media/fonts/titillium-web/Titillium-Web-ExtraLight.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/titillium-web/Titillium-Web-ExtraLight.woff2') format('woff2'),
         url('/media/fonts/titillium-web/Titillium-Web-ExtraLight.woff') format('woff'),
         url('/media/fonts/titillium-web/Titillium-Web-ExtraLight.ttf') format('truetype'),
         url('/media/fonts/titillium-web/Titillium-Web-ExtraLight.svg#titillium_web_extralightRg') format('svg');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'TitilliumWeb-Light';
    src: url('/media/fonts/titillium-web/Titillium-Web-Light.eot');
    src: url('/media/fonts/titillium-web/Titillium-Web-Light.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/titillium-web/Titillium-Web-Light.woff2') format('woff2'),
         url('/media/fonts/titillium-web/Titillium-Web-Light.woff') format('woff'),
         url('/media/fonts/titillium-web/Titillium-Web-Light.ttf') format('truetype'),
         url('/media/fonts/titillium-web/Titillium-Web-Light.svg#titillium_web_lightregular') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'TitilliumWeb-Regular';
    src: url('/media/fonts/titillium-web/Titillium-Web-Regular.eot');
    src: url('/media/fonts/titillium-web/Titillium-Web-Regular.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/titillium-web/Titillium-Web-Regular.woff2') format('woff2'),
         url('/media/fonts/titillium-web/Titillium-Web-Regular.woff') format('woff'),
         url('/media/fonts/titillium-web/Titillium-Web-Regular.ttf') format('truetype'),
         url('/media/fonts/titillium-web/Titillium-Web-Regular.svg#TitilliumWeb') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'TitilliumWeb-SemiBold';
    src: url('/media/fonts/titillium-web/Titillium-Web-SemiBold.eot');
    src: url('/media/fonts/titillium-web/Titillium-Web-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/titillium-web/Titillium-Web-SemiBold.woff2') format('woff2'),
         url('/media/fonts/titillium-web/Titillium-Web-SemiBold.woff') format('woff'),
         url('/media/fonts/titillium-web/Titillium-Web-SemiBold.ttf') format('truetype'),
         url('/media/fonts/titillium-web/Titillium-Web-SemiBold.svg#titillium_web_semiboldregular') format('svg');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'TitilliumWeb-Bold';
    src: url('/media/fonts/titillium-web/Titillium-Web-Bold.eot');
    src: url('/media/fonts/titillium-web/Titillium-Web-Bold.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/titillium-web/Titillium-Web-Bold.woff2') format('woff2'),
         url('/media/fonts/titillium-web/Titillium-Web-Bold.woff') format('woff'),
         url('/media/fonts/titillium-web/Titillium-Web-Bold.ttf') format('truetype'),
         url('/media/fonts/titillium-web/Titillium-Web-Bold.svg#titillium_webbold') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'TitilliumWeb-Black';
    src: url('/media/fonts/titillium-web/Titillium-Web-Black.eot');
    src: url('/media/fonts/titillium-web/Titillium-Web-Black.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/titillium-web/Titillium-Web-Black.woff2') format('woff2'),
         url('/media/fonts/titillium-web/Titillium-Web-Black.woff') format('woff'),
         url('/media/fonts/titillium-web/Titillium-Web-Black.ttf') format('truetype'),
         url('/media/fonts/titillium-web/Titillium-Web-Black.svg#titillium_web_blackregular') format('svg');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'TitilliumWeb-LightItalic';
    src: url('/media/fonts/titillium-web/Titillium-Web-Light-Italic.eot');
    src: url('/media/fonts/titillium-web/Titillium-Web-Light-Italic.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/titillium-web/Titillium-Web-Light-Italic.woff2') format('woff2'),
         url('/media/fonts/titillium-web/Titillium-Web-Light-Italic.woff') format('woff'),
         url('/media/fonts/titillium-web/Titillium-Web-Light-Italic.ttf') format('truetype'),
         url('/media/fonts/titillium-web/Titillium-Web-Light-Italic.svg#titillium_web_lightitalic') format('svg');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'TitilliumWeb-Italic';
    src: url('/media/fonts/titillium-web/Titillium-Web-Italic.eot');
    src: url('/media/fonts/titillium-web/Titillium-Web-Italic.eot?#iefix') format('embedded-opentype'),
         url('/media/fonts/titillium-web/Titillium-Web-Italic.woff2') format('woff2'),
         url('/media/fonts/titillium-web/Titillium-Web-Italic.woff') format('woff'),
         url('/media/fonts/titillium-web/Titillium-Web-Italic.ttf') format('truetype'),
         url('/media/fonts/titillium-web/Titillium-Web-Italic.svg#titillium_webitalic') format('svg');
    font-weight: 400;
    font-style: italic;
}