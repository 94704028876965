.cal {
  width: 100%;
  border-collapse: separate;
  border-spacing: 5px;
  caption {
    text-transform: uppercase;
    @include semibold;
    a {
      border: 0;
    }
  }
  .arrow {
    margin-top: 4px;
    &--left {
      float: left;
      margin-left: 10px;
    }
    &--right {
      float: right;
      margin-right: 10px;
    }
  }
  thead {
    @include visually-hidden;
  }
  tbody {
    th,td {
      padding: 0;
    }
    tr:nth-child(even) {
      td,th {
        background-color: inherit;
      }
    }
  }
  td {
    @include regular;
    text-align: center;
    width: 2em;
    line-height: 2em;
    a {
      color: #fff;
      border: 0;
      border-radius: 100%;
      background-color: $primary-color;
      display: inline-block;
      width: 2em;
      height: 2em;
      line-height: 2em;
      transition: background-color .1s;
      &:hover {
        background-color: #333;
        background-color: rgba(0,0,0,.9);
      }
    }
  }
  .festiu {
    opacity: .4;
  }
}