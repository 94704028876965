.formacion-item {
  &__categ {
    @include typi('big');
    margin-bottom: vr(.5);
    a {
      color: $link-color;
      border: 0;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
  &__pict {
    margin-bottom: vr(1.5);
  }
  &__date {
    @include typi('big');
    display: block;
    margin-bottom: vr(1.5);
    z-index: 1;
    position: relative;
  }
  &__title {
    @media #{$medium-up} {
      //width: 90%;
      //margin-left: auto;
      //margin-right: auto;
    }
  }
  &__loc,
  &__time {
    @include typi('big');
    @include regular;
    color: $primary-color;
    font-size: 1rem;
    padding-left: 40px;
    position: relative;
    margin-bottom: vr(1);
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0px;
      //margin: 0 auto vr(.25);
      top: -4px;
    }
  }
  &__time {
    margin-bottom: vr(1.5);
  }
  &__loc:before {
    @include sprite('icon-loc-2');
  }
  &__time:before {
    @include sprite('icon-time-2');
  }
}