.colab-header {
	.view & {
		margin-bottom: vr(1.5);
	}
	@media #{$medium-up} {
		padding-bottom: 0;
		.view & {
/* 			padding-bottom: vr(3); */
            padding-bottom: vr(1.5);		
		}
	}
	
	h1,
	.apartat {
		text-transform: uppercase;
		text-align: center;
		@include bold;
		@include typi('colab');
		margin-bottom: 0;
		.view & {
			@include extralight;
		}
	}
	&:after {
		content: '';
		@include sprite('pict-colab');
		display: none;
		@media #{$medium-up} {
			display: block;
			margin: vr(.5) auto 0;
			font-size: .6em;
		}
		@media #{$large-up} {
			margin-top: vr(2);
			font-size: 1rem;
		}
		.view & {
			display: none;
		}
	}
}
.colab-prj {
	position: relative;
	margin-bottom: vr(1.5);
	@media #{$medium-up} {
		margin-bottom: 0;
		padding-bottom: vr(6);
	}
	&:after {
		content: '';
		display: block;
		height: 3px;
		width: 100%;
		background-color: #fff;
		@media #{$medium-up} {
			display: none;
		}
	}
	&__title {
		@include typi('bigger');
		text-align: center;
		@media #{$medium-up} {
			text-align: left;
		}
	}
	figure {
		@media #{$medium-up} {
			text-align: left;
		}
	}
	&__title-2 {
		@include typi('big');
		@include bold;
		margin-bottom: vr(.125);
	}
	&__descr-1 {
		@include typi('big');
		@include regular;
		// text-transform: uppercase;
		border-bottom: 1px solid #fff;
		padding-bottom: vr(1);
	}
	.btn {
		@include typi('s1');
		border-width: 3px;
		@media #{$medium-up} {
			position: absolute;
			bottom: vr(2);
			left: 0;
		}
	}
}

.colab-block {
	// margin: 0 $column-gutter*.5 vr(.25);
	margin-bottom: vr(.25);
	padding-left: $column-gutter*.5;
	padding-right: $column-gutter*.5;
	&:last-child {
		margin-bottom: 0;
	}
	a {
		color: #fff;
	}
}
.btn-container {
	&:before {
		display: block;
		content: '';
		@include sprite('ico-donacion-boton');
		margin: 0 auto vr(.5);
	}
}

@media #{$medium-up} {
	.container-brd .row div:first-child .colab-prj {
		padding-right: $column-gutter;
	}
	.container-brd .row div:last-child .colab-prj {
		padding-left: $column-gutter;
	}
}
