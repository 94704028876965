.empleo-item {
  body:not(.view) & {
    > *:last-child {
      margin-bottom: 0;
    }
  }
  @media #{$medium-up} {
    position: relative;
    .view & {
      position: static;
    }
  }
  time {
    display: block;
    margin-bottom: vr(.5);
  }
  h2 {
    @include semibold;
    margin-bottom: vr(.5);
    padding-top: 0;
    @media #{$medium-up} {
      padding-right: vr(5);
    }
    .view & {
      @include typi('s0');
      @include bold;
      text-transform: uppercase;
      padding-right: 0;
    }
    a {
      color: $body-font-color;
      border: 0;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
  
  dl.dl-inline {
   @media #{$medium-up} {
     padding-right: vr(5);
   }
 }  
}