.menu-lang {
	@include regular;
	text-align: right;
	position: static;
	vertical-align: middle;
	margin-bottom: 0;
	display: inline-block;
	margin-left: 15px;
	@media #{$medium-up} {
		.sticky & {
			display: none;
		}
	}
	&--mobile {
		margin-top: 20px;
		li {
		    font-size: 1rem;
		    display: inline-block;
		    margin: 0 vr(.5);
		    vertical-align: middle;
		    padding-left: 0;
		    &:before {
		      display: none;
		    }
			a {
				color: #000 !important;
			}
		  }
		margin-bottom: vr(1);
		@media #{$large-up} {
			display: none;
		}
	}
	&--desktop {
		display: none;
		@media #{$medium-up} {
			display: inline-block;
			text-transform: uppercase;
			position: relative;
			li {
				a {
					color: #fff;
				}
			}
		}
	}
	li {
		@include typi('s1');
		margin: 0;
		&:after {
			content: '/';
			color: #494949;
			margin: 0 .25em;
		}
		&:last-child:after {
			display: none;
		}
		&.current {
			a {
				color: #494949;
				cursor: default;
				&:hover {
					border: 0;
				}
			}
		}
	}
	a {
		color: #000;
		text-decoration: none;
		border: 0;
		.home & {
			color: #fff;
			span {
				svg {
					fill: #fff;
				}
			}
		}
		&:hover {
			border-bottom: 1px solid;
			border-bottom-color: rgba(#fff,.6);
		}
	}

}

.lang-toggle {
	@include typi('s1');
	border-bottom: 1px solid transparent !important;
	&:hover {
		border-bottom: 1px solid !important;
		border-bottom-color: rgba(#fff,.6) !important;
	}
}
.lang-list {
	// @include typi('s1');
	position: absolute;
	left: 0;
	top: 2em;
	background-color: $primary-color;
	text-align: left;
	padding: vr(.25) vr(.25);
	z-index: 1;
	// fletxeta del dropdonw
	&:before {
	  @include triangle(6px, $primary-color, bottom);
	  content: '';
	  position: absolute;
	  left: 5px;
	  top: -12px;
	  z-index: 2;
	  display: block;
	}
	li {
		display: block;
		padding: vr(.125) vr(.25);
		&:after {
			display: none;
		}
		&:last-child {
			a {
				border: 0;
				padding-bottom: 0;
				&:hover {
					border-bottom: 0;
				}
			}
		}
		a {
			display: block;
			position: relative;
			padding-bottom: 6px;
			z-index: 100;
			border-bottom: 1px solid #fff;
			border-bottom-color: rgba(#fff,.2);
			&:hover {
				text-decoration: underline;
				border-bottom: 1px solid #fff;
				border-bottom-color: rgba(#fff,.2);
			}
		}
	}
}