.ctlg-item {
  border-bottom: 1px solid #979797;
  margin-bottom: vr(1);
  &:first-of-type {
    border-top: 1px solid #979797;
    padding-top: vr(1);
  }
  h2 {
    @include bold;
    @include typi('t2');
    margin-bottom: vr(.5);
    a {
      color: #000;
      border: 0;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
  p,dl,ul {
    @include typi('s1');
  }
}