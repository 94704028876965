.columns-container {
  @media only screen and (min-width: 30em) {
    column-count: 2;
    column-gap: vr(1);
  }
  &--3 {
    @media #{$large-up} {
      column-count: 3;
    }
  }
  &__block {
    @media #{$medium-up} {
      page-break-inside: avoid;
    }
    &--brd {
      border-bottom: 1px solid #979797;
      margin-bottom: vr(1);
    }
  }
  h2,h3 {
    page-break-after: avoid;
  }
}