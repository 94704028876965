.column-block {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: vr(1);
  @media #{$medium-up} {
    position: relative;
    border-bottom: 0;
    padding: 0 $column-gutter*.5;
    &:after {
      content: '';
      position: absolute;
      display: block;
      right: -$column-gutter*.5;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #e9e9e9;
    }
  }
  &--last {
    border-bottom: 0;
    margin-bottom: 0;
    @media #{$medium-up} {
      &:after {
        display: none;
      }
    }
  }
  &__item {
    border-bottom: 1px solid #979797;
    margin-bottom: vr(1);
    &:last-child {
      border: 0;
    }
  }
}