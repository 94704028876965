.convocatoria-item {
  // > *:last-child {
  //   margin-bottom: 0;
  // }
  @media #{$medium-up} {
    position: relative;
    .view & {
      position: static;
    }
  }
  time {
    display: block;
    margin-bottom: vr(.5);
  }
  h2 {
    @include semibold;
    margin-bottom: vr(.5);
    padding-top: 0;
    .view & {
      @include typi('s0');
      @include bold;
      text-transform: uppercase;
    }
    @media #{$medium-up} {
      padding-right: vr(5);
    }
    a {
      color: $body-font-color;
      border: 0;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
  figure {
    @media #{$medium-up} {
      display: inline-block;
      margin-left: vr(.5);
      margin-right: vr(.5);
    }
  }
}