.main-nav {
  text-align: center;
  .sticky-header & {
    position: relative;
    top: .3rem;
  }
  @media #{$large-up} {
    margin-right: 11px;
  }
}
.menu-button-1 {
  position: absolute;
  right: $column-gutter/2;
  top: 50%;
  margin-top:-13px;
  @media #{$medium-up} {
    position: static;
    margin-top: rem-calc(19);
  }
  @media #{$large-up} {
    display: none;
  }
}
.menu-button__label {
  display: none;
  @media #{$medium-up} {
    @include typi('s1');
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    vertical-align: top;
    margin-left: vr(.25);
  }
}

.menu-button.is-active .hamburger-inner::after, .menu-button.is-active .hamburger-inner::before {
  background-color: #fff;
}
.hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {

    .home & {
    background-color: #fff;
  }

}

@media only screen and (max-width: 1089px) {
  button[aria-expanded="false"] + .menu {
    display: none;
  }
}
.js .menu {
  @media screen and (max-width: 1089px) {
    &.js-open {
      position: absolute;
      left: 0;
      top: 80px;
      right: 0;
      width: 100%;
      background-color: #fff;
      //background-color: rgba(#000,.9);
      z-index: 2;
    }
  }
  @media screen and (min-width: 48em) and (max-width: 1089px) {
    &.js-open {
      top: 123px;
    }
  }
}
.site-menu {
  @include typi('s1');
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  @include bold;
  @media #{$large-up} {
    text-align: right;
    margin-top: vr(1);
    .sticky-header & {
      margin-top: 0;
    }
  }
  li {
    @media #{$large-up} {

      margin-left: vr(1.5);
      display: inline-block;
    }
  }
  a {
    text-decoration: none;
    color: #000;
    padding: vr(.75) 0;
    display: block;
    border: 0;
    @media #{$large-up} {
      display: inline-block;
      padding: 0;
      height: 50px;
      .home & {
        color: #fff;

      }
      .sticky-header & {
        color: #000;
      }
      &[aria-current="page"] {
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 1em;
          width: 100% !important;
          height: 2px;
          background-color: #0056A4;
        }
        &[aria-haspopup="true"] {
          &:after {
            width: calc(100% - 10px);
          }
        }
      }

      &:hover {
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: .75em;
          width: 100%;
          height: 1px;
          background-color: #fff;
          background-color: rgba(#fff,.6);
        }
      }
      &[aria-haspopup="true"]:hover {
        &:after {
          display: none;
        }
      }
    }
    .home & {
      span {
        svg {
          fill: #fff;
        }
      }
    }
  }
}

// mòbil
@media screen and (max-width: 1089px) {
  .menu {
    padding: 0 vr(2) vr(1) vr(2);
  }
  .site-menu {
    @include semibold;
   /* @media screen and (min-width: 48em) and (max-width: 1089px) {
      column-count: 2;
      column-gap: vr(1);
    }*/
    span[aria-hidden="true"] {
      display: none;
    }
    > li {
      border-top: 1px solid #000;
      border-top-color: rgba(#000,.5);
      @media screen and (min-width: 48em) and (max-width: 1089px) {
        page-break-inside: avoid;
      }
    }
    a:hover {
      text-decoration: underline;
    }
    // submenú
    ul {
      @include regular;
      text-transform: none;
      list-style: none;
      li.first {
        a {
          padding-top: 0;
        }
      }
      li.last {
        a {
          padding-bottom: 0;
        }
      }
      a {
        color: $primary-color;
        padding: vr(.25) 0;
      }
    }
  }
}
.simple-menu {
  @include typi('s1');
  @include regular;
  border-top: 1px solid rgba(#000,.5);
  border-top-color: rgba(#000,.5);
  padding-top: vr(1);
  margin-bottom: 0;
  list-style: none;
  a {
    border: 0;
    color: $primary-color;
    padding: vr(.25) 0;
    text-decoration: none;
    display: block;
  }
  @media #{$large-up} {
    display: none;
  }
}

// desktop
@media #{$large-up} {
  .site-menu {
    li {
      position: relative;
    }
    // dropdown
    ul {
      width: 38em;
      text-align: left;
      z-index: 1;
      background-color: $primary-color;
      background-color: rgba($primary-color,.9);
      padding: vr(.75) vr(.5);
      position: absolute;
      border-radius: 8px;
      // fletxeta del dropdonw
      &:before {
        @include triangle(6px, $primary-color, bottom);
        content: '';
        position: absolute;
        left: vr(1);
        top: -12px;
        z-index: 2;
        display: block;
      }

      li {
        margin-left: 0;
        width: 50%;
        float: left;
        padding: vr(.25) vr(.5);

        a {
          font-size: 13px;
          display: block;
          position: relative;
          z-index: 100;
          color: #fff;
          border-bottom: 1px solid #fff;
          border-bottom-color: rgba(#fff,.2);
          transition: border-bottom-color .1s;
          &:hover {
            border-bottom-color: rgba(#fff,.6);
          }
          &:after {
            display: none !important;
          }
          span {
            display: none !important;
          }
          .sticky-header & {
            color: #fff !important;
          }
        }
        ul {
          display: none !important;

        }
      }
    }

    // submenú més petit
    .submenu-small ul {
      width: 15em;
      li.last {
        width: 100%;
        float: none;
        clear: left;
      }
    }
    a {
      padding-bottom: 12px;
      padding-right: 11px;
      margin-right: -11px;
      span {
        display: block;
        position: absolute;
        right: -5px;
        top: .25em;
      }
    }
    li.right-menu {
      a {
        span {
          right: -15px;
        }
      }
      ul {
        right:0;
        &:before {
          right: vr(1);
          left: auto;
        }
      }
    }
    li:nth-child(4n),
    li:nth-child(5n),
    li:nth-child(6n) {
      a {
        padding-right: 0;
      }
    }
  }
}

// http://heydonworks.com/practical_aria_examples/#submenus
@media #{$large-up} {
  // [aria-label^="example"] ul {
  //   background: #1928cf;
  // }

  // [aria-label^="example"] ul:after {
  //   content: '\0020';
  //   display: block;
  //   clear: both;
  // }

  // [aria-label^="example"] li {
  //   float: left;
  //   vertical-align: top;
  //   list-style: none;
  // }

  // [aria-label^="example"] li + li a {
  //   border-left: 1px solid;
  // }

  // [aria-label^="example"] a {
  //   color: #fff;
  //   display: block;
  //   padding: 0.5em;
  // }

  // [aria-label^="example"] a:hover, [aria-label^="example"] a:focus {
  //   background: #222;
  // }

  [aria-haspopup="true"] {
    position: relative;
  }

  [aria-haspopup="true"] span {
    font-size: .75em;
    padding-left: 0.25em;
    opacity: 1;
  }

  ul[aria-hidden="true"] {
    display: none;
  }

  [aria-haspopup="true"] + ul[aria-hidden="false"] {
    margin-top: 0;
    display: block;
    position: absolute;
    min-width: 200px;
  }

  // [aria-haspopup="true"] + ul li {
  //   float: none;
  // }
}


[lang="en"] .site-menu>li {
    //margin-left: vr(1);
}
