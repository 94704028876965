.more {
  text-align: center;
  margin: vr(1) 0;
  a {
    @include semibold;
    // @include typi(s1);
    text-transform: uppercase;
    display: inline-block;
    border: 0;
    &:before {
      content: '';
      @include sprite(boto-mes);
      display: inline-block;
      vertical-align: middle;
      margin: 0 vr(.25) 0 0;
      transition: opacity .1s;
    }
    &:hover {
      &:before {
        opacity: .8;
      }
    }
  }

  &--neg {
    a {
      color: #fff;
      &:before {
        @include sprite(boto-mes-neg);
      }
    }
  }
}