.panel {
  background-color: #f0f0f0;
  padding: vr(.75);
  margin-bottom: vr(1);
  >*:last-child {
    margin-bottom: 0;
  }
  // p {
  //   margin-bottom: 0;
  // }
  &--2 {
    color: $body-font-color;
		background-color: #fff;
		padding: vr(1);
    border: 1px solid $primary-color;
    border-radius: 8px;
  	@media #{$medium-up} {
			padding: vr(2) vr(5) vr(2) vr(2);
			position: relative;
  	}
		h3 a {
			color: $body-font-color;
			border: 0;
			&:hover {
				border-bottom: 1px solid;
			}
		}
		.event-item__date {
			margin-bottom: vr(.25);
		}
		.event-item__time {
			margin-bottom: vr(.5);
		}
  }
  &--3 {
    background-color: transparent;
    border: 1px solid #979797;
    border-width: 1px 0;
  }
  &--conv {
  	background-color: #fff;
    border: 1px solid $primary-color;
    border-radius: 8px;
  	@media #{$medium-up} {
			padding: vr(2) vr(5) vr(2) vr(2);
			position: relative;
  	}
  	h2 {
  		@include semibold;
  		margin-bottom: vr(.5);
      padding-top: 0;
  		a {
  			color: $body-font-color;
  			border: 0;
  			&:hover {
  				border-bottom: 1px solid;
  			}
  		}
  	}
    p:first-child {
      @include semibold;
      margin-bottom: vr(.5);
      a {
        border-bottom: 0;
        &:hover {
          border-bottom: 1px solid;
        }
      }
    }
  	.btn {
  		margin-bottom: 0;
  		@media #{$small-only} {
  			display: block;
  		}
  	}
  	h3 {
  		@include typi('s0');
  		text-transform: uppercase;
  	}
    figure {
      @media #{$medium-up} {
        text-align: left;
        display: inline-block;
        margin-right: vr(1);
      }
    }
    .inline-list--logos {
      @media #{$medium-up} {
        text-align: left;
      }
      @media #{$large-up} {
        margin-bottom: inherit;
      }
      li {
        margin: 0 vr(.5);
        @media #{$medium-up} {
          margin-left: 0;
          margin-right: vr(1);
        }
      }
    }
  }
}
