table {
  @include typi('s1');
  margin-bottom: vr(1.5);
  text-align: left;
  width: 100%;
  background-color: #fff;
  th {
    @include semibold;
  }
  th,td {
    vertical-align: top;
  }
  thead {
    th {
      text-transform: uppercase;
      border: 1px solid #979797;
      padding: vr(.25);
      @media #{$large-up} {
        padding: vr(.5);
      }
    }
  }
  tbody {
    th,td {
      padding: vr(.5);
    }
    tr:nth-child(even) {
      td,th {
        background-color: #ededed;
      }
    }
  }
}