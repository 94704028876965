.section {
  padding: vr(1) 0;
  @media #{$medium-up} {
    padding: vr(3) 0;
  }
  &--small {
    @media #{$medium-up} {
      padding: vr(1.5) 0;
    }
  }
  &--1 {
    background-color: #f9f9f9;
  }
  &--2 {
    color: #fff;
    background-color: $primary-color;
    background-image: linear-gradient(to left, #008fd1, #0056a4);
  }
  &--3 {
    color: #fff;
    background-color: #000;
    background-image: linear-gradient(to bottom, #616161, #000000);
    .home & {
      background-image: none;
    }
  }
  &--4 {
    color: #fff;
    background-color: #0056a4;
    background-image: linear-gradient(to left, #37e2a9, #0056a4);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0056a4', endColorstr='#37e2a9',GradientType=1 ); /* IE6-9 */
  }
  &--5 {
    background-color: #cbe8ff;
  }
  &--6 {
    color: #fff;
    background-color: $primary-color;
    background-image: linear-gradient(to left, #141c1f, #0056a4);
    a:not(.btn) {
      color: #fff;
      border-bottom-color: #fff;
    }
  }
  &--vmv {
    text-align: center;
    border-radius: 8px;
    padding: 4.5rem 3rem; 
    &:before {
      content: '';
      display: block;
      @include sprite('ico-logo-positivo');
      margin: 0 auto vr(1);
      @media #{$medium-up} {
        display: none;
      }
    }
    h2 {
      text-transform: uppercase;
      margin-top: vr(1);
      @media #{$medium-up} {
        margin-top: 0;
      }
      &:before {
        content: '';
        display: block;
        margin: 0 auto vr(1);
        display: none;
        font-size: 1rem;
        @include sprite('ico-logo-positivo');
        @media #{$medium-up} {
          display: block;
        }
      }
    }
    p,ul {
      @media #{$medium-up} {
        margin-bottom: 0;
      }
    }
  }
  &--data {
    background-image: url('img/edifici-logo-fondo.png'), linear-gradient(to left, #008fd1, #0056a4);
    background-repeat: no-repeat, no-repeat;
    background-position: 50% vr(1), 0 0;
    background-size: 100%, auto;
    margin-bottom: vr(1);
    @media #{$medium-up} {
      background-size: 50%, auto;
    }
    @media #{$medium-up} {
      background-size: contain, auto;
    }
  }
  &--btns {
    @media #{$large-up} {
      padding: vr(5) 0;
    }
  }
  &--enllac-extern {
    background-image: url('img/bg-thi-mobile.jpg');
    background-repeat: no-repeat;
    background-position: right bottom;
    padding:rem-calc(21) 0 rem-calc(18);     
    margin-bottom:30px;

    @media #{$medium-up} {
      padding:rem-calc(44) 0 rem-calc(18);
      background-image: url('img/bg-thi.jpg');      
      background-position: right bottom;
      background-size:auto;
      margin-bottom:25px;    }

   .intro-text{
      margin-top:rem-calc(10);
      margin-right:auto;
      margin-left:auto;
      //text-align: center;
      font-size:rem-calc(22);
      width:rem-calc(248);
    @media #{$medium-up} {
      margin-top:rem-calc(17);
      text-align: left;
      font-size:rem-calc(26);
      width:rem-calc(770);    
    }
   }
   .data-wrapper {
     border-top: solid 1px #979797;
     width:rem-calc(248);
     margin:25px auto 14px;
     text-align:center;
     padding:30px 5px;
     min-height:rem-calc(445);
     @media #{$medium-up} {
      min-height:320px;
      border:none;
      text-align:left;
      width:100%;
      margin:0;
      padding:0;
      width:rem-calc(420);
    }
    @media #{$large-up} {
      width:rem-calc(460);
 
    }
   }
   .logo {
     border:none;
     margin:0 auto rem-calc(18) auto;      
     display:block;
     width:rem-calc(175);
     @media #{$medium-up} {
      margin:0 0 rem-calc(18) 0; 
      width:auto;
    }     
   }
   .btn--solid {
    margin:auto;
    color: rgb(7, 6, 6);
    background-color: #ffb548;
    width: rem-calc(199);
    height: rem-calc(42);
    font-weight:bold;
    border-radius:8px;
    padding: rem-calc(9) rem-calc(45) rem-calc(8);
    font-size: 1rem;
    &:hover {
      color: #000;
      border:2px solid  #d39c49;
    }
  }

  }
  &--newsletter {
    text-align: center;
    margin-top: vr(2);
    .home & {
      margin-top: 0;
    }
    .actualidad & {
      h2 {
        text-transform: uppercase;
        @include typi('bigger');
        @include semibold;
        margin-bottom: vr(.25);
      }
    }
    @media #{$medium-up} {
      padding-bottom: vr(1.5);
    }
    &:before {
      @include sprite('ico-newsletter');
      content: '';
      display: block;
      margin: -2.5rem auto vr(1);
      font-size: .7em;
      @media #{$medium-up} {
        margin-top: -5.5rem;
      }
      @media #{$large-up} {
        font-size: 1em;
        margin-top: -6rem;
      }
    }
    h3 {
      @include typi('bigger');
      @include light;
      padding-top: vr(1);
      border-top: 1px solid #fff;
      border-top-color: rgba(#fff,.5);
    }
    a {
      color: #87e6fa;
    }
    .btn--neg {
      color: #87e6fa;
      &:hover {
        color: $primary-color;
      }
    }
  }

  > .row > div > {
    p,ul,dl,ol,.btn  {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}
