.menu-button-2 {
  display: block;
  margin: vr(.5) auto 0;
  // canvi de color de la icona de menú
  .sub-hd--simple &,
  .group-nav & {
    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
      background-color: $body-font-color;
    }
    &.hamburger--3dy.is-active .hamburger-inner {
      background-color: transparent !important;
    }
  }
  .section--4 & {
    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
      background-color: #fff;
    }
  }
  .investigacion.grupo &,
  .servicios.plataforma & {
    margin: 0 auto;
  }
  @media #{$medium-up} {
    display: none;
  }
}
.sub-nav {
  @include semibold;
  margin-bottom: 50px;
  line-height: 1.2;
  height: auto!important;
  opacity: 1!important;
  display: block;
  @media #{$medium-up} {
    padding-right: 30px;
  }
  li {
    padding: 0;
    border-top: 1px solid #979797;
    margin: 0;
    display: block;
    &:first-child {
      border-top: 1px solid #fff;
    }
    &:before {
      display: none;
    }
    &:hover,
    &.current {
      border-top: 1px solid #fff;
      a{
        background-color: $primary-color;
        color: #fff;
        border-radius: 8px;
        .blog & {
          background-color: #CE6600;
        }
      }
      @media #{$medium-up} {
        position: relative;
        &:before {
          top: 0;
        }
        &:after {
          bottom: 0;
        }
        a{
          border:none;
        }
      }
    }
    ul[aria-hidden=true] {
      margin-top: 0;
      margin-bottom: 5px;
      display: block;
      li {
        border-top: none;
        a {
          background-color: #fff;
          color: #000;
          @include semibold;
          padding-left: 25px;
          font-size: 14px;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          &:hover, &.current {
            color: #000 !important;
          }
        }
        &:hover,
        &.current {
          //border-top: 1px solid #fff;
          a{
            background-color: #CCDDED;
            color: #000;
            border-radius: 8px;
          }
          @media #{$medium-up} {
            position: relative;
            &:before {
              top: 0;
            }
            &:after {
              bottom: 0;
            }
            a{
              border:none;
            }
          }
          ul {
            li {
              a {
                background-color: #fff;
                color: #000;
                border:none;
              }
            }
          }
        }
        ul {
          li {
            a {
              background-color: #fff;
              color: #000;
              padding-left: 40px;
              font-size: 12px;
            }
            &:hover,
        &.current {
          a{
            background-color: #E5EEF5;
            color: #fff;
            border-radius: 8px;
          }
          @media #{$medium-up} {
            position: relative;
            &:before {
              top: 0;
            }
            &:after {
              bottom: 0;
            }
            a{
              border:none;
            }
          }
        }
          }
        }
      }
    }
  }
  li.current + li {
    border-top: 1px solid #fff;
  }
  a {
    border: 0;
    color: $body-font-color;
    font-size: 16px;
    padding-left: 15px;
    padding-top: .8rem;
    padding-bottom: .8rem;
    @include regular;
    display: block;
    .sub-hd--img &,
    .sub-hd--grad &,
    .sub-hd--solid & {
      color: #fff;
    }
  }
  .plat & {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px 30px 10px 30px;
    margin-bottom: 1.5rem;
    li {
      @media #{$large-up} {
        display: inline-block;
      }
      border-top: none;
      margin-right: 40px;
      a {
        color: $primary-color;
        @include semibold;
        font-size: 16px;
        border-radius: 0;
        padding: 0;
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 2px solid transparent;
      }
      &:hover,
        &.current {
          a{
            background-color: transparent;
            border-bottom: 2px solid $primary-color;
          }
        }
    }
  }
}
.sub-nav--bottom {
  margin:vr(.75) 0 0;
  li{
    text-align: left;
    border-bottom: 1px solid #ddd;
    &:before{
      content: none;
    }
    &.current{
      display: none;
    }
    a{
      display: block;
      padding: 1rem 2.5rem 1rem 1rem;
      color: #555;
      border:none;
      position:relative;
      &:before {
        position: absolute;
        content: "";
        top: 5px;
        right: 1rem;
        opacity: .6;
        -webkit-transform:scale(.5);
        transform:scale(.5);
        @include sprite('fletxa-dreta-negre');
      }
    }
  }
   @media #{$medium-up} {
       display: none;
    }
}