.plataforma {
    /* 
     + Globals
     */
     #main {
        margin-bottom: 0!important;
    }
    h1 {
        text-align: left;
        margin-bottom: 1.5rem;
    }
    .sub-hd--img {
        //margin-bottom: 0;
        padding-bottom: 4rem;
	    .sub-hd__bg {
	        position: relative;
	    }
	}
    .apartat {
        margin-bottom: 1.5rem;
    }
    
    .cta-buttons {
        position: relative;
        margin-top: 4.5rem;
        @media #{$medium-up} {
            margin-top: 6rem;
            padding-bottom: 6rem;
        }
        @media #{$large-up} {
            margin-top: 9rem;
        }
    }
    /**
     * MODS
     */
    .block-list__box {
        padding-top: 3rem;
        padding-bottom: 2rem; 
    }
    
    .block-list__box h2 {
        border-bottom: 0 none;
    }
    /**
     * PADDINGS & MARGINS 
     */
    .pd_xxl {
        
        padding-bottom: 40px;
        @media #{$medium-up} {
            padding-top: 40px;
        }
    }
    .txt-w {
        color: white!important;
    }
    .mr_w {
        margin-left: 10px;
        margin-right: 10px;
    }
    /**
     * BUTTONS 
     */
    .cta-btn {
        border-radius: 4px;
        background-color: #ffffff;
        color: #000000;
        cursor: pointer;
        padding: 40px;
        font-family: TitilliumWeb-SemiBold, sans-serif;
        max-width: 300px;
        width: 100%;
        display: inline-block;
        border: 1px solid #ccc;
    }
    /**
     * NEW BLOCKS 
     */
    .featured-li li h2 {
        font-family: TitilliumWeb-Light, sans-serif;
        font-size: 18px;
        line-height: 1.15;
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .featured{
        background-color: #0060ac;
        color: #fff;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
        position:relative;
        margin-top: -3rem;
        @media #{$medium-up} {
            margin-top: 0;
        }
        
        h1 {
            font-size: 1.125rem;
            line-height: 1.4;
            font-family: TitilliumWeb-Bold,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
			font-weight: 700;
			font-style: normal;
			padding-bottom: .75rem;
			margin-bottom: -.75rem;
			text-align: left;
	        &:before {
	            content: '';
	            display: block;
	            width: 99999px;
	            height: 100%;
	            background-color: $primary-color;
	            position: absolute;
	            left: -99999px;
	            top: 0;
	        }
        }
        &-ico {
	        object-fit: contain;
	        object-position: 0;
	        width: 100px;
	        height: 64px;
	        display: block;
	        clear: both;
	        margin-bottom: 20px;
	        &-sm {
		        object-fit: contain;
		        object-position: 0;
		        width: 100px;
		        height: 32px;
		        display: block;
		        clear: both;
		        margin-bottom: 10px;
		    }
        }
    }
    
    ul.sidebar-menu {
        margin: 45px 0;
        margin-left: 1.5rem;
        li {
            list-style: none!important;
            position: inherit!important;
            padding-left: 0;
            a {
                border-top: solid 1px rgba(180, 178, 178, 0.6);
                display: block;
                padding: 10px 0 0!important;
                border-bottom: none!important;
                font-family: TitilliumWeb-Semibold,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
            }
            a.active {
                color: #ccc;
            }
        }
        li:first-child a {
            border-top: none;
        }
        li:before,
        li:after {
            background-color: white!important;
            width: 0;
            height: 0;
        }
    }
    .entry {
        margin-bottom: 60px;
        padding: 0 1rem;
        @media #{$medium-up} {
            padding-left: 4rem;
        }
        h2 {
            display: block;
            margin: 1.6em 0 1em;
            font-size: 28px;
            font-family: TitilliumWeb-ExtraLight, sans-serif;
        }
        ol.numbers,
        .list-numbers ol
         {
            list-style: none;
            counter-reset: my-awesome-counter;
            margin-left: 1rem;
            li {
                ul, ol {
                    border-left: 1px solid #0060ac;
                    margin: 20px 10px;
                    li {
                        margin-left: 10px;
                        list-style: none!important;
                        position: inherit!important;
                        font-family: TitilliumWeb-Light, sans-serif;
                        display: block;
                        font-size: 16px;
                        font-weight: 400;
                        border-bottom: none;
                        padding: 10px 0;
                        color: black;
                    }
                    li::before {
                        background-color: transparent;
                        display: none;
                    }
                }
            }
        }
        ol.numbers>li,
        .list-numbers ol>li
         {
            font-family: TitilliumWeb-Regular, sans-serif;
            counter-increment: my-awesome-counter;
            font-size: 22px;
            font-weight: 600;
            line-height: 1.09;
            color: #000;
            margin-left: 44px;
            margin-bottom: 1.5em;
            position: relative;
        }
        ol.numbers>li::before,
        .list-numbers ol>li::before {
            content: counter(my-awesome-counter);
            color: #000;
            font-size: 1.5rem;
            position: absolute;
            --size: 46px;
            left: calc(-1 * var(--size) - 10px);
            line-height: var(--size);
            width: var(--size);
            height: var(--size);
            top: 0;
            text-align: center;
            border: 1px solid #0060ac;
            background-color: transparent!important;
        }
        ul.numbers {
            border-left: 1px solid #0060ac;
            margin: 20px 10px;
            li {
                margin-left: 10px;
                list-style: none!important;
                position: inherit!important;
                font-family: TitilliumWeb-Light, sans-serif;
                margin-bottom: 20px;
                font-size: 14px;
                border-bottom: 1px solid #e2e2e2;
                padding-bottom: 20px;
                a {
                    display: block;
                    font-size: 16px;
                    font-weight: 400;
                    border-bottom: none;
                    color: #0084c8!important;
                }
                h3 {
                    font-size: 16px;
                }
                h3,
                p {
                    margin-bottom: 5px!important;
                }
            }
            li::before {
                background-color: transparent;
            }
            li::last-child {
                border-bottom: none;
            }
        }
    }
}