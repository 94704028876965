.rel-docs {
  // background-color: #f0f0f0;
  padding: vr(.75) vr(1);
  margin-bottom: vr(1);
  text-align: left;
  border: 1px solid $primary-color;
  h2 {
    @include typi('s1');
    @include bold;
    text-transform: uppercase;
    margin-bottom: vr(.5);
    border-bottom: 1px solid #ebebeb;
    padding-bottom: vr(.5);
  }
  ul {
    @extend .bordered;
    @extend .simple-list;
    margin-bottom: 0;
    li {
      border-bottom-color: #ebebeb;
      padding-left: 32px;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      &:before {
        content: '';
        display: block;
        background-color: transparent;
        border-radius: 0;
        top: 5px;
        @include sprite('ico-download');
      }
      em {
        @include typi('s1');
        display: block;
        color: $body-font-color;
      }
    }
  }
}