body {
 overflow-x: hidden; // per evitar scroll horitzonal en alguns navegadors quan s'utlitza la classe .u-wider en algun element.
}

.formacion main {
  @media #{$medium-up} {
    overflow-x: hidden;
  }
}

#content {
  @media #{$large-up} {
    padding-top: rem-calc(100);
  }
}

.row--wider {
  max-width: 90rem;
  margin-top: vr(-.25);
  @media #{$large-up} {
    margin-top: 0;
  }
  > .columns {
    &:first-child {
      @media #{$medium-up} {
        padding-right: vr(.125);
      }
      @media #{$large-up} {
        padding-right: vr(.5);
      }
    }
    &:nth-child(2) {
      @media #{$medium-up} {
        padding-left: vr(.125);
      }
      @media #{$large-up} {
        padding-left: vr(.5);
        padding-right: vr(.5);
      }
    }
    &:nth-child(3) {
      @media #{$large-up} {
        padding-left: vr(.5);
      }
    }
  }

  // reescribim amplades del foundation
  @media #{$large-up} {
    .item-wide {
      width: 38.5%;
    }
    .item-narrow {
      width: 23%;
    }
  }
}

.container-brd {
  @media #{$medium-up} {
    border-bottom: 3px solid #fff;
    margin-bottom: vr(3);
  }
}

main {
  margin-bottom: vr(3);
  .sticky-header & {
    @media #{$large-up} {
      padding-top: rem-calc(204);
    }
  }
  .home.sticky-header &,
  .subhome.sticky-header & {
    @media #{$large-up} {
      padding-top: 0;
    }
  }
  &.mb0 {
    margin-bottom: 0;
  }
  .investigacion.grupo & {
    margin-bottom: 0;
    @media #{$medium-up} {
       margin-bottom: vr(3);
       &.mb0 {
        margin-bottom: 0;
      }
    }
  }

  .formacion.subhome & {
    margin-top: rem-calc(106);
    @media #{$medium-up} {
      margin-top: rem-calc(123);
    }
    @media #{$large-up} {
      margin-top: rem-calc(169);
    }
  }
  .formacion.view & {
    margin-top: 0;
  }
}

// figure
figure {
  text-align: center;
  &.main-pict {
    margin: vr(2) 0;
  }
  .equip-wrapper & {
    display: inline-block;
    margin-right: vr(.5);
    margin-left: vr(.5);
  }
}
figcaption {
  @include typi(s1);
  @include semibold;
  padding: vr(.5) 0 0;
  .equip-wrapper & {
    @include regular;
  }
  .oferta.view & {
    text-align: left;
    position: relative;
    padding-left: vr(1);
    @include regular;
    &:before {
      font-size: 1rem;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 1em;
      @include sprite('camera');
    }
  }
}

// paràgraf destacat
.dest {
  @include typi(t3);
  // @include italic;
  margin: vr(1.5) 0;
  // position: relative;
  // padding: vr(.25) 0 vr(.25) vr(1.75);
  text-align: center;
  &:before,
  &:after {
    content: '';
    display: block;
    width: 80%;
    height: 1px;
    background-color: $primary-color;
    margin: 0 auto;
  }
  &:before {
    margin-bottom: vr(1);
  }
  &:after {
    margin-top: vr(1);
  }
}
.dest-2 {
  @include typi('dest');
  padding-left: vr(1);
  border-left: 1px solid #979797;
  margin-bottom: vr(1.5);
}

// baixar arxiu destacat
.dl {
  background-color: #f4f4f4;
  padding: vr(.75) vr(.5);
  position: relative;
  border-bottom: 1px solid #979797;
  padding-left: rem-calc(60);
  &:before {
    content: '';
    position: absolute;
    margin-right: vr(.5);
    // @include sprite(icon-vincle-destacat);
    left: 15px;
    top: 15px;
  }
  a {
    border: 0;
    &:hover {
      text-decoration: underline;
    }
  }
}

// video embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  margin: 0 0 vr(.75);
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

// descarregar arxiu
.arxiu {
  display: inline-block;
  @include typi(s1);
  @include bold;
  margin-bottom: vr(.75);
  border: 0;
  &:hover {
    text-decoration: underline;
  }
  &:before {
    content: '';
    vertical-align: middle;
    margin-right: 8px;
  }
  &--pdf {
    &:before {
      // @include sprite(icon-bases-pdf);
    }
  }
}
// imatge grup d'investigació
.grupo-img {
  text-align: center;
  margin-bottom: vr(2);
  img {
    width: 100%;
  }
}

// títols amb icones de mapes
.map-icon {
  @include typi('t4');
  @include bold;
  margin-bottom: vr(1.5);
  text-align: center;
  @media #{$medium-up} {
    padding-top: rem-calc(110);
    position: relative;
    text-align: left;
  }
  &:before {
    content: '';
    display: block;
    font-size: 1rem;
    margin: 0 auto vr(.5);
    @media #{$medium-up} {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
    }
  }
  &--intern {
    &:before {
      @include sprite('ico-internacional');
    }
  }
  &--nac {
    &:before {
      @include sprite('ico-nacional');
      @media #{$medium-up} {
        margin-top: rem-calc(14);
      }
    }
  }
  &--auton {
    &:before {
      @include sprite('ico-autonomico');
      @media #{$medium-up} {
        margin-top: rem-calc(3);
      }
    }
  }
}

.formacion-container {
  @media #{$medium-up} {
    padding: vr(1);
    margin-top: vr(-1.5);
  }
  @media #{$large-up} {
    margin-top: vr(-3);
    padding: vr(1.5);
  }
  &--2 {
    background-color: $primary-color;
    @media #{$small-only} {
      padding-top: vr(1.5);
    }
    >*:last-child {
      margin-bottom: 0;
    }
    h2 {
      color: #fff;
    }
    @media #{$medium-up} {
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: -200%;
        top: 0;
        width: 200%;
        height: 100%;
        background-color: $primary-color;
      }
    }
  }
}

// continguts pendents de disseny
.pendent {
  border: 2px solid red;
  position: relative;
  &:before {
    content: 'Pendent de definició';
    color: #fff;
    background-color: red;
    padding: .5em;
    display: block;
    text-align: left;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

// open/closed
.open {
  color: $open-color;
}
.closed {
  color: $closed-color;
}

// mapa google
.map {
  width: 100%;
  height: 400px;
  overflow: hidden;
  @media #{$medium-up} {
    height: 550px;
  }
  @media #{$large-up} {
    height: 650px;
  }
}

// img dreta/esq
.img-right {
  img {
    @media #{$medium-up} {
      float: right;
      margin-left: vr(1);
    }
  }
}
.img-left {
  img {
    @media #{$medium-up} {
      float: left;
      margin-right: vr(1);
    }
  }
}
.image-footer {
    font-size: rem-calc(14);
    line-height: 1.6;
    @include bold;
    padding: 0 0 .75rem;
    margin-top: rem-calc(-20);
    //text-align: center;
}
.last-newsletter {
  figure {
    text-align: left;
  }
}
.newsletter {
  .columns-container {
    margin-bottom: 30px;
  }
}
.last-newsletter h2 a{
    color:#000;
    border: 0;
    &:hover {
        border-bottom: 0.0625rem solid;
    }
}

.publicacio {
	font-size: rem-calc(15);
	.authors {
	   font-weight: bold;
	   margin-bottom: 0;
	   display: inline;
	}
	.title {
	   display: inline;
	   font-size: rem-calc(15);
	   font-weight: normal;
	   line-height: rem-calc(21);
	   font-family: $body-font-family;
	}
}
.list-block-2{
  @include undolist;
  @media #{$medium-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
      // Available options: 1-12 by default, and false.
      $per-row: 2,

      // This controls how much space is between each item in the block grid.
      // Use a variable or any pixel or em values.
      $spacing: 60px,

      // This controls whether or not base styles come through, set to false to leave out.
      $base-style: true
    );
  }

  li{
    >strong:first-child{
      display: block;
      font-size: rem-calc(20);
      margin-bottom: -10px;
    }
    padding-bottom: 24px;
    text-align: left;
  }
}

.list-block-3{
  @include undolist;
  @media #{$medium-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
      // Available options: 1-12 by default, and false.
      $per-row: 3,

      // This controls how much space is between each item in the block grid.
      // Use a variable or any pixel or em values.
      $spacing: 60px,

      // This controls whether or not base styles come through, set to false to leave out.
      $base-style: true
    );
  }

  li{
    >strong{
      display: block;
      font-size: rem-calc(20);
      margin-bottom: -10px;
    }
    padding-bottom: 24px;
    text-align: left;
  }
}

.list-block-4{
  @include undolist;
  @media #{$medium-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
      // Available options: 1-12 by default, and false.
      $per-row: 2,

      // This controls how much space is between each item in the block grid.
      // Use a variable or any pixel or em values.
      $spacing: 60px,

      // This controls whether or not base styles come through, set to false to leave out.
      $base-style: true
    );
  }
  @media #{$large-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
      // Available options: 1-12 by default, and false.
      $per-row: 4,

      // This controls how much space is between each item in the block grid.
      // Use a variable or any pixel or em values.
      $spacing: 60px,

      // This controls whether or not base styles come through, set to false to leave out.
      $base-style: true
    );
  }
  li{
    >strong{
      display: block;
      font-size: rem-calc(20);
      margin-bottom: -10px;
    }
    padding-bottom: 24px;
    text-align: left;
  }
}
@supports (display: flex) {
  @media #{$medium-up} {
    .list-block-2{
        &.list-links-btn,&.llista_cards{
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: flex-start;
         justify-content: flex-start;
          flex-wrap: wrap;
          li{
            padding:rem-calc(10 30);
            flex: 1;
            flex-basis: 48%;
            max-width: 48%;
          }
        }
        &.llista_cards{
          margin:0 -2% 0 0;
          li{
            margin-right: 2%;
          }
        }
        &.list-links-btn{
          margin-left: 0;
          li{
            padding-left: 0;
            max-width: 50%;
          }
        }
    }
    .list-block-3{
        &.list-links-btn,&.llista_cards{
          display: -webkit-flex;
          display: flex;
         -webkit-justify-content: flex-start;
         justify-content: flex-start;
          flex-wrap: wrap;
          li{
            padding:rem-calc(10 30);
           flex: 1;
           flex-basis: 31%;
            max-width: 31%;
          }
        }
        &.llista_cards{
          margin:0 -2.33% 0 0;
          li{
            margin-right: 2.33%;
          }
        }
        &.list-links-btn{
          margin-left: 0;
          li{
            padding-left: 0;
            min-width: 33.33333%;
          }
        }
    }
    .list-block-4{
      &.list-links-btn,&.llista_cards{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
       justify-content: flex-start;
        flex-wrap: wrap;
        li{
          padding:rem-calc(10 30);
         flex: 1;
         flex-basis: 23%;
          max-width: 23%;
        }
        &.llista_cards{
          margin:0 -2% 0 0;
          li{
            margin-right: 2%;
          }
        }
        &.list-links-btn{
          margin-left: 0;

          li{
            padding-left: 0;
            min-width: 25%;
          }
        }
      }
    }
  }
}
.list-links-btn {
  margin-bottom: vr(1.5);
  @include undolist;
  li{
    text-align: center;
    margin-bottom: vr(1);

     a{
      //box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px 1px rgba(0,0,0,.07);
      border: 1px solid #ACACAC;
      color: #000;
      line-height: normal;
      margin: 0;
      padding: rem-calc(15 25);
      display: block;
      text-decoration: none;
      @include single-transition();
      background: #fff;
      text-align: left;
      position: relative;
      height: 100%;
      width: 100%;
      text-transform: uppercase;
      text-align: center;
      @include bold;
      &:hover{
        box-shadow: 0 4px 4px rgba($primary-color,.24), 0 0 4px rgba($primary-color,.12), 0 6px 18px rgba(43,133,231,.12);
        transform: translate3d(0,-2px,0);
        text-decoration: none;
      }
    }

  }
}
.list-links-btn2 {
  @include undolist;
  li{
    text-align: center;
    margin-bottom: vr(1);
     a{
      padding: rem-calc(15 25);
      display: block;
      text-decoration: none;
      @include single-transition();
      color: #fff;
      @include bold;
      text-transform: uppercase;
      line-height: normal;
      background-color: $primary-color;
      height: 100%;
      &:hover{
        box-shadow: 0 4px 4px rgba($secondary-color,.24), 0 0 4px rgba($secondary-color,.12), 0 6px 18px rgba(43,133,231,.12);
        background-color: $secondary-color;
        text-decoration: none;
        color: #000;
      }
    }

  }
}

.mt {
	&-0 {
	   margin-top: 0;
	}
	&-1 {
       margin-top: vr(1);
    }
    &-2 {
       margin-top: vr(2);
    }
    &-3 {
       margin-top: vr(3);
    }
    &-4 {
       margin-top: vr(4);
    }
}

.mb {
    &-0 {
       margin-bottom: 0;
    }
    &-1 {
       margin-bottom: vr(1);
    }
    &-2 {
       margin-bottom: vr(2);
    }
    &-3 {
       margin-bottom: vr(3);
    }
    &-4 {
       margin-bottom: vr(4);
    }
}

.upper {
	text-transform: uppercase !important;
}

.list-links-btn3 {
  margin-bottom: vr(1.5);
  @include undolist;
  li{
    text-align: center;
    margin-bottom: vr(1);
    display: table;
     a{
      //box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px 1px rgba(0,0,0,.07);
      border: 1px solid #ACACAC;
      line-height: normal;
      margin: 0;
      padding: rem-calc(15 25);
      display: inline-block;
      text-decoration: none;
      @include single-transition();
      background: #fff;
      text-align: left;
      position: relative;
      height: 100%;
      width: 100%;
      text-transform: uppercase;
      text-align: center;
      vertical-align: middle;
      display: table-cell;
      &:hover{
        box-shadow: 0 4px 4px rgba($primary-color,.24), 0 0 4px rgba($primary-color,.12), 0 6px 18px rgba(43,133,231,.12);
        transform: translate3d(0,-2px,0);
        text-decoration: none;
      }
    }


  }
}
.filtre-cataleg {
  padding: 70px;
  margin-bottom: 40px;
  color: #fff;
  background-color: $primary-color;
  background-color: rgba(0,86,164, 1);
  h2 {
    text-align: center;
  }
    a:not(.btn) {
      color: #fff;
      border-bottom-color: #fff;
    }
    border-radius: 8px;
}
.box-list {
  margin-bottom: 60px;
  margin-top: 30px;
  .subportada__menu & {
    margin-top: 0;
  }
  li {
    a {
      display: block;
      border: 1px solid $primary-color;
      color: $primary-color;
      border-radius: 8px;
      padding: 25px 40px 55px;
      margin-right: 20px;
      font-size: 20px;
      @include semibold;
      &:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }
}
.box-list-menu-sub {
  list-style: none;
  li {
    border: 1px solid $primary-color;
    color: $primary-color;
    border-radius: 8px;
    padding: 25px 40px;
    margin-right: 20px;
    font-size: 20px;
    @include semibold;
    &::before {
      content: none !important;
      display: none !important;
    }
    a {
      text-decoration: none;
      border-bottom: none;
    }
    ul {
      display: block;
      list-style: none;
      li {
        border: none;
        font-size: 18px;
        margin-left: 0;
        @include regular;
        padding: 0;
        &::marker {
          content: none;
        }
        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.list-logos {
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;
}
.cifras {
  h2 {
    color: #008CCE;
    text-align: center;
    font-size: 24px;
    @include regular;
    margin-bottom: 50px;
  }
  margin-top:3rem;
  .nums-list {
    margin-bottom: 50px;
    text-align: center;
    li {
      margin-bottom: 0;
      padding-bottom: 0;
      span {
        font-size: 32px;
      }
      &:nth-child(-n+1) {
        div {
          border-top: 4px solid #008CCE;
        }
      }

      @media #{$medium-up} {
        &:nth-child(-n+2) {
          div {
            border-top: 4px solid #008CCE;
          }
        }
      }
      @media #{$large-up} {
        &:nth-child(-n+4) {
          div {
            border-top: 4px solid #008CCE;
          }
        }
      }
      div {
        border-bottom: 4px solid #008CCE;
        max-width: 138px;
        min-height: 165px;
        padding-top: 20px;
        margin: 0 auto;
      }

    }
  }
}
.convocatoria-item {
  h1 {
    text-align: left;
  }
  .dest-conv {
    border: 1px solid $primary-color;
    border-radius: 8px;
    padding: 40px 50px 30px;
    margin-bottom: 20px;
    @media #{$medium-up} {
      .status {
        float: right;
      }

    }
  }
}
h2.title-sociedad {
  font-size: 28px;
  @include black;
  @media #{$medium-up} {
    display: inline-block;
  }
}
p.more-sociedad {
  @media #{$medium-up} {
    float:right;
  }
  a {
    text-decoration: none;
    border-bottom: none;
    @include semibold;
    display: block;
    padding-top: 8px;
    &:before {
      content: '';
      background-image:url('/media/img/ico-mas.svg') ;
      display: inline-block;
      vertical-align: middle;
      margin: 0 vr(.25) 0 0;
      transition: opacity .1s;
      width: 20px;
      height: 20px;
    }
    &:hover {
      &:before {
        opacity:.5;
      }
    }
  }
}
.date-sociedad {
  &:after {
    content:'';
    display: block;
    border-bottom: 1px solid $primary-color;
    width: 16px;
    margin-top: 5px;
  }
}
h3.title-sociedad {
  color: #000;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 20px;
}
.img-border {
  //padding-left: 30px;
  border-radius: 8px;
}
.second-row {
  margin-top: 30px;
}
.banner-blog {
  background-image: linear-gradient(to left, rgba(0, 143, 209, 0.0) 0%, rgba(206, 102, 0, 1) 100%), url('/media/css/img/banner-blog@2x.png');
  border-radius: 8px;
  padding: 30px;
  color: #fff;
  max-height: 282px;
  h2 {
    @include black;
    font-size: 28px;
  }
  p {
    font-size: 18px;
    max-width: 300px;
  }
}
.btn--transp {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
  border-radius: 8px;
  border: 1px solid #fff;
}
.banner-sociedad {
    border-radius: 8px;
    margin-top: 30px;
    background-color: $primary-color;
    color: #fff;
    @include black;
    font-size: 28px;
    display: block;
    text-align: center;
    padding: 60px;
    height: 180px;
    border-bottom: 0;
    &:hover {
      border-bottom: 0;
    }
}
.banner-news {
  padding-top: 70px;
}
.banner-colabora {
  background: linear-gradient(90deg, rgba(1, 89, 164, 1) 0%, rgba(55, 223, 169, 1) 100%);
  position: relative;
  @include regular;
  padding-top: 70px;
  &:before {
    content: '';
    background-image: url(/media/css/img/imatge-caixa-colabora.svg);
    height: 146px;
    width: 120px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.banner-ucci {
  background-color: #EFEFEF;
  color: #474747;
  line-height: 18px;
  h2 {
    @include black;
    margin-bottom: 5px;
  }
  span {
    @include regular;
    font-size: 16px;
  }
}
.banner-portada {
  border-radius: 8px;
  height: 200px;
  padding:30px;
  .title-banner {
    font-size: 28px;
    color: #fff;
    line-height: 32px;
    @include light;
    max-width: 550px;
    display: block;
    strong {
      @include black;
    }
    @media #{$medium-up} {
      font-size: 38px;
      line-height: 42px;
    }
  }
}
.video {
  position: relative;
  margin-bottom: 50px;
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 6px;
    width: 100%;
    background-color: rgba(0,0,0,.3);
  }
  .home-intro {
    position: absolute;
    top: 200px;
    left: 5%;
    @media #{$large-up} {
      top: 300px;
      left: 15%;
    }
    h2 {
      color: #fff;
      font-size: 32px;
      text-align: left;
      max-width: 830px;
      @media #{$large-up} {
        font-size: 52px;
      }
    }
  }

}
.actualitat-home {
  margin-top: 20px;
  @media #{$medium-up} {
    margin-top: 0px;
  }
}
.text-donacio {
  margin-top: 30px;
}
