.link {
  padding: vr(.5) vr(1);
  text-align: left;
  border: 1px solid $primary-color;
  width: 100%;
  padding-left: 56px;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: transparent;
    border-radius: 0;
    left: 24px;
  }
  a {
    border-bottom: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
  em {
    @include typi('s1');
    display: block;
  }
  &--dl {
    &:before {
      top: 15px;
      @include sprite('ico-download');
    }
  }
  &--view {
    &:before {
      top: 18px;
      @include sprite('ico-view');
    }
    .expandable & {
      max-width: 760px;
    }
  }
}