.cookies {
    @include typi(s1);
    background-color: #333;
    background-color: rgba(#000,.9);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
    padding-top: vr(1);
    padding-bottom: vr(1);
    @media #{$medium-up} {
        padding-top: vr(2);
        padding-bottom: vr(2);
    }
    p {
        margin-bottom: 0;
        color: #fff;
    }
    a {
        color: #fff;
        text-decoration: none;
        white-space: nowrap;
        // border-bottom: 1px solid;
        // &:hover {
        //     border-bottom: 0;
        // }
    }

    .btn {
        display: block;
        width: 10rem;
        margin: vr(1) auto 0;
        color: $primary-color;
        border: 0;
        text-transform: none;
        &:focus,
        &:hover {
            color: #fff;
        }
        // @media #{$medium-up} {
        //     margin-top: vr(.75);
        // }
    }
}
