// .link-no-decoration {
//   img {
//     text-decoration: none;
//   }
// }

.section--6 a.link-no-decoration {
  // color:transparent;
  border-bottom-color: transparent
}

.button-ope {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}